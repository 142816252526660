import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { HideIcon, ViewIcon } from "../assets/img";
import Loader from "../components/Loader";
import authStore from "../stores/auth";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const { loginApi, loading, getUserDetailAPI } = authStore((state) => ({
    loginApi: state?.loginApi,
    getUserDetailAPI: state?.getUserDetailAPI,
    loading: state?.loading,
  }));

  return (
    <div>
      <main className="h-screen flex justify-center pt-[80px] bg-[url('../../assets/img/icon/contact.webp')]">
        <section className="flex justify-center w-full h-full my-auto contact relative after:content-[''] after:w-full after:absolute after:top-0 after:left-0 after:bg-origin-border after:object-cover after:bg-center after:h-full after:bg-cover after:z-[-1] ">
          <div className="container px-4 mx-auto">
            <div className="py-10 max-w-7xl mx-auto relative">
              <div>
                <h2 className="text-white text-3xl xl:text-5xl max-w-md sm:text-3xl md:text-5xl opacity-25 lg:leading-tight xl:leading-tight">
                  Welcome back,
                </h2>
                <div className="">
                  <div className="py-6 mb-5 flex">
                    <h2 className="text-white xl:text-4xl  xl:leading-tight sm:text-2xl text-2xl md:text-4xl">
                      D.O.P.E Golf
                    </h2>
                    <h2 className="ml-2 text-red-500 xl:text-4xl xl:leading-tight sm:text-2xl text-2xl md:text-4xl">
                      Player Login
                    </h2>
                  </div>
                </div>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email("Email is invalid")
                      .required("Email is required"),
                    password: Yup.string().required("Password is required"),
                  })}
                  onSubmit={(fields) => {
                    let data = {};
                    data.email = fields.email;
                    data.password = fields.password;
                    data.type = "web";
                    loginApi(data, navigate).then(
                      (res) => res?.status === 200 && getUserDetailAPI()
                    );
                  }}
                  render={({ errors, status, touched }) => (
                    <Form className="w-full h-full">
                      {/* {loading && <CustomSpinner />} */}
                      <div className="mx-auto py-8">
                        <div className="mt-5 flex flex-col">
                          <label
                            className="text-gray-500 py-2 pt-3 text-xs"
                            htmlFor="email"
                          >
                            Your Email
                          </label>
                          <Field
                            autoComplete="off"
                            className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 mb-1"
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Enter email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-red-500 text-xs"
                          />
                        </div>
                        <div className="mt-5 flex flex-col">
                          <label
                            className="text-gray-500 py-2 pt-3 text-xs"
                            htmlFor="email"
                          >
                            Your Password
                          </label>
                          <Field
                            className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 mb-1"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            id="password"
                            placeholder="Password"
                          />
                          <div className="relative">
                            <div className="absolute right-2 top-[-30px] transform -translate-y-1/2">
                              {showPassword ? (
                                <img
                                  src={ViewIcon}
                                  alt="view_icon"
                                  className="h-5 w-5 cursor-pointer"
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              ) : (
                                <img
                                  src={HideIcon}
                                  alt="hide_icon"
                                  className="h-5 w-5 cursor-pointer"
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              )}
                            </div>
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="text-red-500 text-xs"
                          />
                        </div>
                      </div>

                      <div className="w-44">
                        <button
                          disabled={loading}
                          className={`${
                            !loading ? `bg-themecolor` : `bg-themecolor/40`
                          } w-full justify-center flex py-2 rounded-lg font-light text-white sm:text-sm text-xs mt-5`}
                        >
                          LOGIN WITH PLAYER{loading && <Loader />}
                        </button>

                        <div className="my-5 text-white w-full justify-center flex">
                          OR
                        </div>
                        <button
                          type="reset"
                          onClick={() =>
                            window.open("https://admin.dopegolf.com", "_self")
                          }
                          disabled={loading}
                          className={`w-full justify-center bg-white flex px-4 py-2 rounded-lg font-light text-black sm:text-sm text-xs mt-5`}
                        >
                          LOGIN WITH INTRODUCER
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Login;
