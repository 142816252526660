import React, { useEffect } from "react";
import {
  Layer2Image,
  MASK1,
  Testimonal1,
  Testimonal2,
  Testimonal3,
} from "../assets/img";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Loader from "../components/Loader";
import authStore from "../stores/auth";

const Home = () => {
  const { loading, createFeedbackApi } = authStore((state) => ({
    createFeedbackApi: state.createFeedbackApi,
    loading: state.loading,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const options = {
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      900: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };

  return (
    <div>
      <main>
        <section className="banner bg-cover bg-center h-screen md:pt-[60px] sm:pt-[40px] banner-img">
          <div className="container px-4 mx-auto h-full flex justify-center items-center">
            <div className="sm:w-1/2 w-1 sm:block hidden"></div>
            <div className="text-white bg-black/60 p-4 sm:w-1/2 w-full">
              <div className="mx-auto text-center">
                <div className="flex flex-col text-start sm:font-normal xl:text-6xl lg:text-5xl md:text-4xl sm:text-3xl text-2xl">
                  <span>WHERE</span>
                  <span className="text-red-500 mt-1">SNIPER</span>
                  <span className="text-red-500 mt-1">TECHNOLOGY</span>
                  <span className="mt-1">MEETS GOLF</span>
                </div>
                <div className="mt-1 flex flex-wrap gap-2 text-start sm:font-normal xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
                  <span>YOUR GAME |</span>
                  <span className="text-red-500">YOUR DATA</span>
                  <span>| ANYTIME |</span>
                  <span className="text-red-500">ANYWHERE</span>
                </div>

                <div className="xl:mt-36 lg:mt-36 md:mt-24 sm:mt-16 mt-8 flex flex-wrap gap-2 text-start sm:font-normal xl:text-3xl lg:text-2xl md:text-xl sm:text-lg text-md">
                  <span>The Days of guessing your</span>
                  <span className="text-red-500">Total Carry Distances</span>
                  <span>are</span>
                  <span className="text-red-500">OVER</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="px-4 mx-auto h-full sm:flex block justify-center items-center text-white bg-black">
          <div className="p-8 sm:w-1/2 w-full my-auto">
            <div className="flex flex-col text-start sm:font-normal xl:text-6xl lg:text-5xl md:text-4xl sm:text-3xl text-2xl">
              <span>D.O.P.E. GOLF</span>
              <span className="text-red-500 mt-1">APPLICATION</span>
            </div>
            <div className="my-8 flex flex-wrap gap-2 text-start sm:font-normal xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
              <span>YOUR GAME |</span>
              <span className="text-red-500">YOUR DATA</span>
              <span>| ANYTIME |</span>
              <span className="text-red-500">ANYWHERE</span>
            </div>
            <p className="lg:text-lg md:text-md sm:text-sm text-xs">
              By collecting your average launch parameters for each club by
              utilizing a launch monitor (launch angle, spin rate, ball speed,
              spin axis), the D.O.P.E. Golf app will import the real time
              atmospheric variables based off your GPS location (temperature,
              humidity, barometric pressure, elevation, and wind). The D.O.P.E.
              Golf Ballistic Algorithm will then compute to a 99% accuracy how
              far your ball will carry if you can recreate that same swing,
              regardless of changes in atmospheric variables.
            </p>
            <div>
              <Link to="/app" className="text-red-500 text-xl my-2">
                Go To The App
              </Link>
            </div>
          </div>
          <div className="sm:w-1/2 w-full md:min-h-[730px] sm:min-h-[530px] min-h-[400px] md:p-16 p-8 flex items-center">
            <img src={MASK1} alt=''/>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Home;
