import React from "react";

const Faq = () => {
  return (
    <div>
      <main className="text-white md:pt-[60px] sm:pt-[40px]">
        <section className="h-[50vh] bg-no-repeat w-full bg-[url('../../assets/img/icon/intro.webp')]">
          <div className="container mx-auto px-4 h-full flex justify-center items-center">
            <h1 className="lg:text-6xl sm:text-4xl text-3xl">
              Frequently Asked Questions
            </h1>
          </div>
        </section>
        <section>
          <div className="lg:max-w-full lg:py-32 py-20">
            <div className="lg:flex lg:flex-wrap lg:items-start md:flex md:flex-wrap md:items-center  lg:justify-center ">
              <div className="md:w-2/5 ">
                <div className="px-5 xl:ml-52 lg:ml-20  mb-10 text-center md:text-left 2xl:ml-80">
                  <h3 className="lg:text-2xl md:text-xl lg:pt-4 text-xl underline decoration-tgreen underline-offset-8 mb-4">
                    How to place an order
                  </h3>
                  <ul className="lg:text-lg md:text-base opacity-60 font-thin ">
                    <li className="lg:pt-4 sm:pt-2 pt-1">
                      How to cancel your order
                    </li>
                    <li className="lg:pt-4 sm:pt-2 pt-1">
                      How to make changes in order
                    </li>
                    <li className="lg:pt-4 sm:pt-2 pt-1">
                      How to return Product
                    </li>
                    <li className="lg:pt-4 sm:pt-2 pt-1">
                      How to pay for the Product
                    </li>
                    <li className="lg:pt-4 sm:pt-2 pt-1">
                      How to Earn Dope Golf Points while Shopping
                    </li>
                    <li className="lg:pt-4 sm:pt-2 pt-1">
                      How To Redeem Point
                    </li>
                    <li className="lg:pt-4 sm:pt-2 pt-1">
                      How to avail offers and Promotions
                    </li>
                    <li className="lg:pt-4 sm:pt-2 pt-1">
                      How to earn Dope Golf Money (Reward Points)
                    </li>
                  </ul>
                </div>
              </div>
              <div className="md:w-3/5 ">
                <div className="bg-[#292A2C] rounded-lg lg:ml-4  overflow-hidden">
                  <h3 className="lg:text-3xl sm:text-2xl text-xl sm:px-6 py-4 xl:px-24 lg:px-12 bg-[#4F5052] px-8">
                    How to place an order
                  </h3>
                  <div className="xl:pl-24 lg:px-12  sm:px-6 lg:max-w-2xl pb-12 pt-7 px-8">
                    <h4 className="lg:text-xl md:text-[17px] text-[17px] font-light xl:py-5 lg:py-3">
                      Placing an order on Dopegolf.com is an easy task.
                    </h4>
                    <h4 className="xl:text-3xl lg:text-2xl sm:text-xl text-xl py-3 text-tgreen font-light xl:py-6 lg:py-4 md:py-4 ">
                      For new customer
                    </h4>
                    <p className="font-bold xl:text-base lg:text-sm md:text-sm  text-sm opacity-50">
                      If you haven't registered with us earlier, it's simple and
                      free to create an account. Start by clicking on the "Sing
                      Up" button at the top of each page. The popup window will
                      then open with the "sign-in" page. Once there, click the
                      link that tells "New Customers / Create an Account." Next,
                      you'll go to the account registration page where you'll be
                      asked to enter some relevant details to create your
                      account. For future transactions, all you have to do is
                      enter your registered password.
                    </p>
                    <h4 className="xl:text-3xl lg:text-2xl md:text-xl text-xl text-tgreen font-light xl:py-6 lg:py-4 md:py-4 py-3">
                      For returning customer
                    </h4>
                    <p className="font-bold xl:text-base text-sm lg:text-sm md:text-sm opacity-50">
                      If you are a well-established Dope Golf customer who has
                      made an online purchase with us before, all you have to do
                      is sign in using your email address and password. Upon
                      completion of your order, you will receive an order number
                      via email & SMS on your registered number. We recommend
                      that you need to save this to your records before you
                      receive your order.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Faq;
