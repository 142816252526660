import moment from "moment";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { CloseIcon, Golf1Image } from "../assets/img";
import Loader from "../components/Loader";
import authStore from "../stores/auth";
import { ACCESS_TOKEN } from "../utils/constants";
import Footer from "./Footer";
import Header from "./Header";
import NavBar from "./NavBar";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { HideIcon, ViewIcon } from "../assets/img";

export default function DefaultLayout(props) {
  const [display, setDisplay] = useState(false);
  const [dLogoutModal, setDLogoutModal] = useState(false);
  const [resetPWDModal, setResetPWDModal] = useState(false);
  const [dCancelModal, setDCancelModal] = useState(false);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    getUserDetailAPI,
    resetPWDApi,
    getUserData,
    cancelSubscriptionApi,
    loading,
  } = authStore((state) => ({
    getUserDetailAPI: state.getUserDetailAPI,
    resetPWDApi: state.resetPWDApi,
    getUserData: state.getUserData?.data,
    cancelSubscriptionApi: state.cancelSubscriptionApi,
    loading: state.loading,
  }));

  useEffect(() => {
    localStorage.getItem(ACCESS_TOKEN) && getUserDetailAPI();
  }, [getUserDetailAPI]);

  const clickOnLogout = () => {
    localStorage.clear();
    setModal(false);
    navigate("/");
    window.location.reload(false);
  };

  const date1 = new Date(
    moment.unix(getUserData?.payment?.period?.start).format("MM/DD/yyyy")
  );
  const date2 = new Date(
    moment.unix(getUserData?.payment?.period?.end).format("MM/DD/yyyy")
  );
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  const cancelModal = (
    <>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="fixed inset-0 w-full h-full backdrop-blur-sm bg-black/50" />
        <div className="flex items-center min-h-screen px-4 py-8 cursor-pointer">
          <div className="relative w-full max-w-md mx-auto">
            <div className="bg-[#192035] rounded-lg">
              <div className="flex justify-between px-5 pt-5 pb-2">
                <h1 className="text-themecolor text-center text-xl my-auto">{`Subscription Conformation`}</h1>
              </div>
              <div className="flex justify-center w-full">
                <div className="w-full px-5">
                  <div className="text-white py-2 rounded-lg">
                    {`Are you sure you want to cancel subscription?`}
                  </div>
                  <div className="flex justify-end mt-2">
                    <div className="flex justify-end">
                      <button
                        className={`bg-transparent border border-1 border-slate-500 flex px-4 py-2 rounded-lg font-light text-slate-500 text-sm my-3`}
                        onClick={() => setDCancelModal(false)}
                      >
                        CANCEL
                      </button>
                      <button
                        className={`bg-themecolor ml-3 flex px-4 py-2 rounded-lg font-light text-white text-sm my-3`}
                        onClick={() => {
                          cancelSubscriptionApi({
                            data: getUserData?.payment?.subscription_id,
                            setDCancelModal: setDCancelModal,
                          }).then(
                            (res) => res.status === 200 && getUserDetailAPI()
                          );
                        }}
                      >
                        CANCEL SUBSCRIPTION {loading && <Loader />}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const logoutModal = (
    <>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="fixed inset-0 w-full h-full backdrop-blur-sm bg-black/50" />
        <div className="flex items-center min-h-screen px-4 py-8 cursor-pointer">
          <div className="relative w-full max-w-md mx-auto">
            <div className="bg-[#192035] rounded-lg">
              <div className="flex justify-between px-5 pt-5 pb-2">
                <h1 className="text-themecolor text-center text-xl my-auto">{`Logout Conformation`}</h1>
              </div>
              <div className="flex justify-center w-full">
                <div className="w-full px-5">
                  <div className="text-white py-2 rounded-lg">
                    {`Are you sure you want to logout?`}
                  </div>
                  <div className="flex justify-end mt-2">
                    <div className="flex justify-end">
                      <button
                        className={`bg-transparent border border-1 border-slate-500 flex px-4 py-2 rounded-lg font-light text-slate-500 text-sm my-3`}
                        onClick={() => setDLogoutModal(false)}
                      >
                        CANCEL
                      </button>
                      <button
                        className={`bg-themecolor ml-3 flex px-4 py-2 rounded-lg font-light text-white text-sm my-3`}
                        onClick={() => {
                          clickOnLogout();
                          setDLogoutModal(false);
                        }}
                      >
                        LOG OUT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  const resetmainPWDModal = (
    <>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="fixed inset-0 w-full h-full backdrop-blur-sm bg-black/50" />
        <div className="flex items-center min-h-screen px-4 py-8 cursor-pointer">
          <div className="relative w-full max-w-md mx-auto">
            <div className="bg-[#192035] rounded-lg">
              <div className="flex justify-between px-5 pt-5 pb-2">
                <h1 className="text-themecolor text-center text-xl my-auto">{`Reset Password`}</h1>
              </div>
              <div className="flex justify-center w-full">
                <div className="w-full px-5">
                  <div className="text-white py-2 rounded-lg">
                    <Formik
                      initialValues={{
                        conformPassword: "",
                        newPassword: "",
                        password: "",
                      }}
                      validationSchema={Yup.object().shape({
                        password: Yup.string()
                          .required("Password is required")
                          .min(3, "Password must be at 3 char long"),
                        newPassword: Yup.string()
                          .required("New Password is required")
                          .min(3, "New Password must be at 3 char long"),
                        conformPassword: Yup.string()
                          .required("Confirm Password is required")
                          .oneOf(
                            [Yup.ref("newPassword")],
                            "Confirm Passwords does not match"
                          ),
                      })}
                      onSubmit={(fields) => {
                        resetPWDApi(fields, navigate).then(
                          (res) =>
                            res?.status === 200 && setResetPWDModal(false)
                        );
                      }}
                      render={({ errors, status, touched }) => (
                        <Form className="w-full h-full">
                          {/* {loading && <CustomSpinner />} */}
                          <div className="mx-auto ">
                            <div className="mt-2 flex flex-col">
                              <label
                                className="text-gray-500 py-2 pt-3 text-xs"
                                htmlFor="password"
                              >
                                Password
                              </label>
                              <Field
                                className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 mb-1"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                id="password"
                                placeholder="Password"
                              />
                              <div className="relative">
                                <div className="absolute right-2 top-[-30px] transform -translate-y-1/2">
                                  {showPassword ? (
                                    <img
                                      src={ViewIcon}
                                      alt="view_icon"
                                      className="h-5 w-5 cursor-pointer"
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={HideIcon}
                                      alt="hide_icon"
                                      className="h-5 w-5 cursor-pointer"
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="text-red-500 text-xs"
                              />
                            </div>
                            <div className="mt-2 flex flex-col">
                              <label
                                className="text-gray-500 py-2 pt-3 text-xs"
                                htmlFor="newPassword"
                              >
                                New password
                              </label>
                              <Field
                                className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 mb-1"
                                type={showNewPassword ? "text" : "password"}
                                name="newPassword"
                                id="newPassword"
                                placeholder="New password"
                              />
                              <div className="relative">
                                <div className="absolute right-2 top-[-30px] transform -translate-y-1/2">
                                  {showNewPassword ? (
                                    <img
                                      src={ViewIcon}
                                      alt="view_icon"
                                      className="h-5 w-5 cursor-pointer"
                                      onClick={() =>
                                        setShowNewPassword(!showNewPassword)
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={HideIcon}
                                      alt="hide_icon"
                                      className="h-5 w-5 cursor-pointer"
                                      onClick={() =>
                                        setShowNewPassword(!showNewPassword)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <ErrorMessage
                                name="newPassword"
                                component="div"
                                className="text-red-500 text-xs"
                              />
                            </div>
                            <div className="mt-2 flex flex-col">
                              <label
                                className="text-gray-500 py-2 pt-3 text-xs"
                                htmlFor="conformPassword"
                              >
                                Confirm Password
                              </label>
                              <Field
                                className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 mb-1"
                                type={showConfirmPassword ? "text" : "password"}
                                name="conformPassword"
                                id="conformPassword"
                                placeholder="Confirm Password"
                              />
                              <div className="relative">
                                <div className="absolute right-2 top-[-30px] transform -translate-y-1/2">
                                  {showConfirmPassword ? (
                                    <img
                                      src={ViewIcon}
                                      alt="view_icon"
                                      className="h-5 w-5 cursor-pointer"
                                      onClick={() =>
                                        setShowConfirmPassword(
                                          !showConfirmPassword
                                        )
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={HideIcon}
                                      alt="hide_icon"
                                      className="h-5 w-5 cursor-pointer"
                                      onClick={() =>
                                        setShowConfirmPassword(
                                          !showConfirmPassword
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <ErrorMessage
                                name="conformPassword"
                                component="div"
                                className="text-red-500 text-xs"
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="flex justify-end mt-2">
                              <div className="flex justify-end">
                                <button
                                  className={`bg-transparent border border-1 border-slate-500 flex px-4 py-2 rounded-lg font-light text-slate-500 text-sm my-3`}
                                  onClick={() => setResetPWDModal(false)}
                                >
                                  CANCEL
                                </button>
                                <button
                                  type="submit"
                                  disabled={loading}
                                  className={`${
                                    !loading
                                      ? `bg-themecolor`
                                      : `bg-themecolor/40`
                                  } ml-3 flex px-4 py-2 rounded-lg font-light text-white text-sm my-3`}
                                >
                                  SUBMIT{loading && <Loader />}
                                </button>
                              </div>
                            </div>
                            {/* <button
                              disabled={loading}
                              className={`${
                                !loading ? `bg-themecolor` : `bg-themecolor/40`
                              } flex px-4 py-2 rounded-lg font-light text-white text-sm mt-5`}
                            >
                              SUBMIT
                            </button> */}
                          </div>
                        </Form>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const accountModal = (
    <>
      <div className="fixed inset-0 z-[42] overflow-y-auto">
        <div className="fixed inset-0 w-full h-full backdrop-blur-sm bg-black/50" />
        <div className="flex items-center min-h-screen px-4 py-8 cursor-pointer">
          <div className="relative w-full max-w-md sm:mx-auto mx-2">
            <div className="bg-[#192035] rounded-lg">
              <div className="flex justify-between p-5">
                <h1 className="text-themecolor text-start sm:text-xl text-md">{`Account Details`}</h1>
                <div
                  className="w-6 h-6 bg-white/30 rounded my-auto"
                  onClick={() => setModal(false)}
                >
                  <img src={CloseIcon} alt="" className="w-6 h-6 p-1" />
                </div>
              </div>
              <div className="flex justify-center px-5">
                <div className="w-full text-white">
                  <div className="flex justify-between mb-5">
                    <div className="flex">
                      <div className="flex sm:justify-start justify-center sm:w-20 sm:h-20 w-14 h-14 rounded-full">
                        <img
                          alt=""
                          src={getUserData?.image || Golf1Image}
                          className="rounded-full"
                        />
                      </div>
                      <div className="my-auto ml-2">
                        <h3 className="sm:text-2xl text-lg">{`${
                          getUserData?.firstName || ""
                        } ${getUserData?.lastName || ""}`}</h3>
                        <h6 className="text-slate-500 sm:text-sm text-xs mt-0 w-[200px] truncate">
                          {getUserData?.email || ""}
                        </h6>

                        <h4 className="text-tgreen sm:text-[13px] text-[10px] uppercase ">
                          {getUserData?.payment?.plan || ""}
                        </h4>
                      </div>
                    </div>
                    <div className="my-auto">
                      <div className="mx-auto">
                        <div
                          onClick={() => setDLogoutModal(true)}
                          target="_blank"
                          className={`mx-auto bg-themecolor text-white px-4 py-1 font-thin rounded-lg sm:text-sm text-xs w-20`}
                          rel="noreferrer"
                        >
                          LOG OUT
                        </div>
                      </div>
                      <div
                        onClick={() => setResetPWDModal(true)}
                        target="_blank"
                        className={`bg-themecolor text-white px-4 py-1 mt-2 font-thin rounded-lg sm:text-sm text-xs my-auto`}
                        rel="noreferrer"
                      >
                        Reset Password
                      </div>
                    </div>
                  </div>
                  {getUserData?.payment?.subscription_id ? (
                    <div>
                      <hr className="border border-t-0 border-slate-400" />
                      <div className="flex justify-between my-3">
                        <div className="flex">
                          <h3 className="text-slate-500 text-start sm:text-xl text-sm">{`Remaining Days : `}</h3>
                          <h3 className="ml-2 text-themecolor text-start sm:text-xl text-sm">{` ${
                            diffDays + " days"
                          }`}</h3>
                        </div>

                        <button
                          onClick={() => setDCancelModal(true)}
                          className={`border border-1 border-themecolor text-themecolor px-3 py-1.5 font-thin rounded-lg sm:text-[10px] text-[8px] my-auto`}
                        >
                          CANCEL SUBSCRIPTION
                        </button>
                      </div>
                      <hr className="border border-t-0 border-slate-400" />
                      <div className="flex justify-between my-3">
                        <div className="flex">
                          <h3 className="text-slate-500 text-start sm:text-xl text-sm">{`Download Application : `}</h3>
                        </div>

                        <button
                          className={`border border-1 border-themecolor text-themecolor px-3 py-1.5 font-thin rounded-lg sm:text-[10px] text-[8px] my-auto`}
                        >
                          <a
                            href="https://play.google.com/store/search?q=dope%20golf&c=apps"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Get Application
                          </a>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full flex mb-5 justify-center items-center">
                      <button
                        onClick={() => {
                          setModal(false);
                          navigate(`/register/s1xuuy5ci7ty8iw9`);
                        }}
                        className={`border border-1 border-themecolor text-themecolor px-3 py-1.5 font-normal rounded-lg sm:text-[10px] text-[8px] my-auto`}
                      >
                        PURCHASE SUBSCRIPTION
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="bg-black">
      {modal && accountModal}
      {resetPWDModal && resetmainPWDModal}
      {dLogoutModal && logoutModal}
      {dCancelModal && cancelModal}
      <NavBar setDisplay={setDisplay} display={display} setModal={setModal} />
      <Header setDisplay={setDisplay} display={display} setModal={setModal} />
      <Outlet />
      <Footer />
    </div>
  );
}
