import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuIcon, WhiteLogo } from "../assets/img";

const Header = ({ display, setDisplay, setModal }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const accessToken = localStorage.getItem("accessToken");

  const handleLogout = () => {
    setModal(true);
    // localStorage.clear();
    // navigate("/");
  };

  return (
    <>
      <header className="fixed top-0 left-0 right-0 bg-black text-white z-40 pt-0">
        <nav className="flex flex-wrap pl-4 justify-between items-center">
          <div className="w-1/2 lg:w-1/5 lg:pl-16 py-2">
            <div className="flex flex-wrap items-center">
              <div onClick={() => navigate("/")} className="outline-none">
                <img
                  src={WhiteLogo}
                  className="block sm:w-[129px] w-20 cursor-pointer"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="menu w-1/2 lg:w-4/5">
            <div className="flex lg:justify-center justify-end items-end lg:items-center w-full">
              <div className="group relative w-full">
                <div className="w-full flex justify-end items-end">
                  <img
                    onClick={() => setDisplay(!display)}
                    src={MenuIcon}
                    alt=""
                    className="w-4 h-4 flex lg:hidden mr-4 cursor-pointer"
                  />
                </div>
                <ul className="xl:space-x-6 lg:space-x-3 xl:relative lg:relative absolute top-full right-0 flex-wrap hidden lg:flex lg:bg-transparent bg-black">
                  <li className="cursor-pointer px-8 py-2 text-base">
                    <div
                      className={`${
                        location?.pathname.startsWith("/app") && `text-red-500`
                      }`}
                      onClick={() => navigate("/app")}
                    >
                      D.O.P.E Golf App
                    </div>
                  </li>

                  <li className="cursor-pointer px-8 py-2 text-base">
                    <div
                      className={`${
                        location?.pathname?.includes("truing-combine") &&
                        `text-red-500`
                      }`}
                      onClick={() => navigate("/truing-combine")}
                    >
                      Truing Combine
                    </div>
                  </li>

                  <li className="cursor-pointer px-8 py-2 text-base">
                    <div
                      className={`${
                        location?.pathname?.includes("introducer") &&
                        `text-red-500`
                      }`}
                      onClick={() => navigate("/introducer")}
                    >
                      Become an introducer
                    </div>
                  </li>
                  <li className="cursor-pointer px-8 py-2 text-base">
                    <div
                      className={`${
                        location?.pathname?.includes("about") && `text-red-500`
                      }`}
                      onClick={() => navigate("/about")}
                    >
                      About
                    </div>
                  </li>

                  {accessToken ? (
                    <li className="cursor-pointer px-8 py-2 text-base">
                      <div
                        className={`${
                          location?.pathname?.includes("account") &&
                          `text-red-500`
                        }`}
                        onClick={() => navigate("/account")}
                      >
                        Account
                      </div>
                    </li>
                  ) : (
                    <li className="cursor-pointer px-8 py-2 text-base">
                      <div
                        className={`${
                          location?.pathname?.includes("login") &&
                          `text-red-500`
                        }`}
                        onClick={() => navigate("/login")}
                      >
                        Login
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
