import React, { useEffect } from "react";

const InstructionalVideo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="sm:my-24 my-16">
      <section className="px-4 my-4 mx-auto h-full sm:flex block justify-center items-center text-white bg-black">
        <div className="p-8 md:w-1/2 w-full my-auto">
          <div className="flex flex-col text-start sm:font-normal xl:text-6xl lg:text-5xl md:text-4xl sm:text-3xl text-2xl">
            <span>D.O.P.E. GOLF</span>
            <span className="text-red-500 mt-1">TRUING COMBINE</span>
            <span className=" mt-1">INSTRUCTIONAL</span>
            <span className="mt-1">VIDEO</span>
          </div>
        </div>
        <div className="md:w-1/2 w-full lg:min-h-[700px] md:min-h-[500px] sm:min-h-[400px] min-h-[300px] md:p-8 p-4 flex items-center">
          <div className="container px-4 mx-auto max-w-6xl">
            <div className="w-full overflow-hidden sm:h-[650px] h-[250px]">
              <iframe
                className="object-contain"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/-RmUADCWI4A"
                title="All Sports Golf Battle | Dude Perfect"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              {/* <video controls className="w-full h-full">
                <source
                  src="https://www.w3schools.com/html/mov_bbb.mp4"
                  type="video/mp4"
                />
              </video> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default InstructionalVideo;
