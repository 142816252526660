import React from "react";
import { useNavigate } from "react-router-dom";
import { InstaIcon, WhiteLogo } from "../assets/img";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="text-white sm:grid sm:grid-cols-3 sm:mx-auto text-center w-full items-center sm:p-12 p-4">
      <div className="xl:flex xl:flex-wrap items-center">
        <h2 className="pr-2 flex items-center justify-center">
          Copyright <i className="ri-copyright-line px-1"></i>{" "}
          {new Date().getFullYear()}
        </h2>
        <span className="text-sm text-gray-500">
          D.O.P.E Golf -All Rights Reserved
        </span>
      </div>
      <div className="text-center cursor-pointer">
        <div onClick={() => navigate("/")}>
          <img
            src={WhiteLogo}
            className="sm:w-24 mx-auto xl:w-[150px] sm:block hidden py-2"
            alt=""
          />
        </div>
      </div>
      <div className="flex flex-wrap items-center sm:justify-end justify-center w-full xl:text-base sm:text-sm">
        <div
          onClick={() => navigate("/terms-condition")}
          className="cursor-pointer text-gray-400"
        >
          T & C
        </div>
        <div
          onClick={() => navigate("/privacy-policy")}
          className="cursor-pointer text-gray-400 pl-3 pr-7"
        >
          Privacy Policy
        </div>
        <div className="flex flex-wrap items-center">
          <h2>
            <span className="text-gray-500">Follow Us On</span>
          </h2>
          <img src={InstaIcon} className="mx-1" width="28px" alt="" />
          <h2>Instagram</h2>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
