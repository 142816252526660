import axios from "axios";
import { ACCESS_TOKEN } from "../utils/constants";

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_HOST,
});
const accessToken = `${localStorage.getItem(ACCESS_TOKEN)}`;
axiosApi.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    // const statusCode = error?.response?.status;

    // if (statusCode === 401) {
    //   window.location.href = "/login";
    // }

    return Promise.reject(error);
  }
);

export { axiosApi };

// import axios from "axios";

// // export const baseURL = "https://portal.mybubbleapp.co.uk/api/";
// // export const baseURL = "http://3.142.235.73:4040/api/";
// export const baseURL = "http://18.116.123.138:4040/api/";

// const accessToken = localStorage.getItem("accessToken");
// axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

// export default axios.create({
//   baseURL,

//   // add additional headers
// });
