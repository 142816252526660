import React from "react";

const Pricing = () => {
  return (
    <div>
      {" "}
      <main className="text-white md:pt-[60px] sm:pt-[40px]">
        <section className="h-[50vh] bg-no-repeat w-full bg-[url('../../assets/img/icon/intro.webp')]">
          <div className="container mx-auto px-4 h-full flex justify-center items-center">
            <h1 className="lg:text-6xl">Become an Introducer</h1>
          </div>
        </section>
        <section>
          <div className="container mx-auto px-4">
            <div className="max-w-5xl mx-auto lg:text-xl">
              <div className="py-28">
                <p>
                  Thank you for your interest in becoming a registered
                  Introducer for Foresight Sports Europe.
                </p>

                <p className="pt-9">
                  We value your support in growing the Foresight family, and
                  with each successful introduction that invests in one of our
                  products, we will reward you with either a cash payment or
                  Foresight credit you choose.
                </p>
              </div>
              <div>
                <h2 className="text-tgreen text-3xl">What you Need to do…</h2>
                <ol className="list-decimal py-7 font-normal text-lg px-8 text-gray-400">
                  <li className="py-3">
                    Please carefully complete all boxes below accurately and to
                    the best of your knowledge. We use this information to
                    review your application.
                  </li>
                  <li className="py-3">
                    Once you are happy everything is correct and there are no
                    typing mistakes, click “Submit Your Application for
                    Approval”
                  </li>
                  <li className="py-3">
                    A member of the Foresight Sports Europe team will review
                    your application request, usually within 24 hours.
                  </li>
                  <li className="py-3">
                    Once you are approved you will be sent, via email to the
                    address provided on this application, our Introducer
                    Agreement and associated Terms and Conditions.
                  </li>
                  <li className="py-3">
                    When you receive the email, simply follow the instructions.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="py-24 relative after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full bg-[url('../../assets/img/icon/contact.webp')] after:bg-cover after:bg-center after:z-[-1]">
          <div className="container px-4 mx-auto">
            <div className="lg:max-w-5xl sm:max-w-2xl mx-auto lg:text-xl">
              <div>
                <h3 className="lg:text-7xl md:text-4xl sm:text-4xl text-3xl">
                  Subscriptions
                </h3>
                <h4 className="sm:text-xl font-extralight py-2">
                  Application Form
                  <span className="text-gray-600">/ Subcriptions </span>
                </h4>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8 py-20">
                <div className="rounded-lg sm:mb-10 mb-10 text-center plan">
                  <h3 className="bg-[#4F5052] py-3 border-2 border-hidden text-base font-thin border-[#FFAE12] tracking-widest rounded-t-lg">
                    BASIC PLAN
                  </h3>
                  <h4 className="text-6xl text-tgreen pt-9">
                    9.99<span className="text-4xl">$</span>
                  </h4>
                  <h5 className="font-thin text-lg">Per month</h5>
                  <p className="font-thin text-base py-6 opacity-50">
                    What you get
                  </p>
                  <ul className="list-disc text-left px-14 text-base grid grid-rows-1 gap-7">
                    <li>
                      The information inputted at onboarding by the introducer
                    </li>
                    <li>
                      Live feed data, from selected 3rd party data sources
                      regarding current air pressure, air temperature, wind
                      speed / direction and location elevation above sea level
                    </li>
                  </ul>
                  <button className="bg-themecolor px-5 py-4 font-thin rounded-lg tracking-wide sm:mb-4 text-sm mt-9">
                    CONTINUE
                  </button>
                </div>

                <div className="border-2 border-hidden rounded-lg sm:mb-6 text-center border-[#FFAE12] plan hover:rounded-t-lg">
                  <h3 className="bg-[#4F5052] py-3 text-base border-2 border-hidden font-thin tracking-widest border-[#FFAE12] rounded-t-lg">
                    PRIMIUM PLAN
                  </h3>
                  <h4 className="text-6xl text-tgreen pt-9">
                    14.99<span className="text-4xl">$</span>
                  </h4>
                  <h5 className="font-thin text-lg">Per month</h5>
                  <p className="font-thin text-base py-6 opacity-50">
                    What you get
                  </p>
                  <ul className="list-disc text-left px-14 text-base grid grid-rows-1 gap-7">
                    <li>
                      All the <b>BASIC PLAN</b> features are included:
                    </li>
                    <li>
                      run all of the shot distance calculations for the coming
                      hours<span className="text-tgreen">***</span>
                    </li>
                  </ul>
                  <button className="bg-themecolor px-5 py-4 font-thin rounded-lg tracking-wide sm:mb-4 text-sm mt-28">
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Pricing;
