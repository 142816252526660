/* eslint-disable react/no-unescaped-entities */
import React from "react";

const Loader = () => {
  return (
    <div class="ml-2 loader-2 center"><span></span></div>
    // <div className="ml-2 loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>
  );
};

export default Loader;
