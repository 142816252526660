import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import authStore from "../../stores/auth";
import Loader from "../../components/Loader";

const BecomeAnIntroducer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const { introducerRegisterApi, loading } = authStore((state) => ({
    introducerRegisterApi: state.introducerRegisterApi,
    loading: state.loading,
  }));

  const clickOnSubmit = (fields, resetForm) => {
    introducerRegisterApi(fields, navigate).then(
      (res) => res.status === 200 && resetForm()
    );
    return false;
  };
  return (
    <div className="sm:my-24 my-16">
      <section className="px-4 my-4 mx-auto h-full sm:flex block justify-center items-center text-white bg-black">
        <div className="p-8 sm:w-1/2 w-full my-auto">
          <div className="flex flex-col text-start sm:font-normal xl:text-6xl lg:text-5xl md:text-4xl sm:text-3xl text-2xl">
            <span>D.O.P.E. GOLF</span>
            <span className="text-red-500 mt-1">INTRODUCER</span>
            <span className="mt-1">SIGN UP</span>
          </div>

          <div className="my-8 lg:text-lg md:text-md sm:text-sm text-xs">
            Thank you for your interest in becoming a registered Introducer for
            D.O.P.E. Golf.
          </div>
          <div className="xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
            TOP 4 Introducers of the year competition
          </div>
          <span className="text-red-500 xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
            Click Here to find out more
          </span>
        </div>
        <div className="flex flex-col sm:w-1/2 w-full md:min-h-[730px] sm:min-h-[530px] min-h-[400px]">
          <div className="max-w-5xl mx-auto mt-5">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                mobileNo: "",
                address: "",
                city: "",
                state: "",
                country: "",
                zipcode: "",
                pga_number: "",
                golf_course_name: "",
                golf_store_name: "",
                golf_store_number: "",
                type: "",
                latitude: 18.551,
                longitude: 73.548,
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string().required("First name is required"),
                lastName: Yup.string().required("Last name is required"),
                email: Yup.string()
                  .email("Email is invalid")
                  .required("Email is required"),
                mobileNo: Yup.string()
                  .required("Phone number is required")
                  .max(16, "Phone Number must be less than 16 characters"),
                address: Yup.string().required("Address is required"),
                city: Yup.string().required("City is required"),
                state: Yup.string().required("State is required"),
                country: Yup.string().required("Country is required"),
                zipcode: Yup.string().required("Zipcode is required"),
                type: Yup.string().required("Basic Info is required"),
              })}
              onSubmit={(fields, { resetForm }) => {
                let data = {
                  firstName: fields?.firstName,
                  lastName: fields?.lastName,
                  email: fields?.email,
                  mobileNo: fields?.mobileNo,
                  address: fields?.address,
                  city: fields?.city,
                  state: fields?.state,
                  country: fields?.country,
                  zipcode: fields?.zipcode,
                  latitude: 18.551,
                  longitude: 73.548,
                };

                if (fields?.pga_number) {
                  data.pga_number = fields?.pga_number;
                }
                if (fields?.golf_course_name) {
                  data.golf_course_name = fields?.golf_course_name;
                }
                if (fields?.golf_store_name) {
                  data.golf_store_name = fields?.golf_store_name;
                }
                if (fields?.golf_store_number) {
                  data.golf_store_number = fields?.golf_store_number;
                }
                if (fields?.type) {
                  data.type = fields?.type;
                }
                clickOnSubmit(data, resetForm);
              }}
              render={({ errors, status, touched, setFieldValue }) => (
                <Form className="w-full h-full">
                  <div className="flex flex-col text-center sm:font-normal xl:text-5xl lg:text-4xl md:text-3xl sm:text-2xl text-xl">
                    <span>Introducer Application</span>
                  </div>
                  <div className="my-10">
                    <h4 className="text-lg tracking-wide text-red-500 pb-4">
                      Basic Info
                    </h4>
                    <select
                      className="inline bg-transparent outline-none border-b border-b-[#4F5052] pb-3 text-[#4F5052] items-center "
                      placeholder="Type of Introducer"
                      onChange={(e) => setFieldValue("type", e.target.value)}
                    >
                      <option value="" selected disabled hidden>Type of Introducer</option>
                      <option className="text-black" value="PGA_Professional" >
                        PGA Professional
                      </option>
                      <option className="text-black" value="Golf_store">
                        Golf Store
                      </option>
                      <option className="text-black" value="Individual">
                        Individual
                      </option>
                    </select>

                    <ErrorMessage
                      name="type"
                      component="div"
                      className="text-red-500 text-xs mt-1"
                    />
                    <div className="mt-4 lg:flex lg:flex-wrap">
                      <div className="lg:w-1/2 pr-3">
                        <label className="text-xs text-[#4F5052]">
                          First Name *
                        </label>
                        <Field
                          type="text"
                          name="firstName"
                          id="firstName"
                          className="block lg:w-full sm:w-full w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl"
                          placeholder="First Name"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                      <div className="lg:w-1/2">
                        <label className="text-xs text-[#4F5052] mt-6">
                          Last Name *
                        </label>
                        <Field
                          type="text"
                          name="lastName"
                          id="lastName"
                          className="block lg:w-full sm:w-full w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl"
                          placeholder="Last Name"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                    </div>
                    <div className="lg:flex lg:flex-wrap">
                      <div className="lg:w-1/2 pr-3">
                        <label className="text-xs text-[#4F5052] mt-6">
                          Your email *
                        </label>
                        <Field
                          type="text"
                          name="email"
                          id="email"
                          className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl "
                          placeholder="Email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                      <div className="lg:w-1/2">
                        <label className="text-xs text-[#4F5052] mt-6">
                          Phone Number*
                        </label>

                        <Field
                          type="number"
                          name="mobileNo"
                          id="mobileNo"
                          className="w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl"
                          placeholder="000-000-0000"
                        />
                        <ErrorMessage
                          name="mobileNo"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                    </div>
                    <div className="lg:flex lg:flex-wrap">
                      <div className="lg:w-1/2 pr-3">
                        <label className="text-xs text-[#4F5052] mt-6">
                          PGA Member Number (If Applicable) *
                        </label>
                        <Field
                          type="number"
                          name="pga_number"
                          id="pga_number"
                          className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl "
                          placeholder="PGA Member Number"
                        />
                      </div>
                      <div className="lg:w-1/2">
                        <label className="text-xs text-[#4F5052] mt-6">
                          Golf Course Name (If Applicable)*
                        </label>

                        <Field
                          type="text"
                          name="golf_course_name"
                          id="golf_course_name"
                          className="w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl"
                          placeholder="Golf Course Name"
                        />
                      </div>
                    </div>
                    <div className="lg:flex lg:flex-wrap">
                      <div className="lg:w-1/2 pr-3">
                        <label className="text-xs text-[#4F5052] mt-6">
                          Golf Store Name (If Applicable) *
                        </label>
                        <Field
                          type="text"
                          name="golf_store_name"
                          id="golf_store_name"
                          className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl "
                          placeholder="Golf Store Name"
                        />
                      </div>
                      <div className="lg:w-1/2">
                        <label className="text-xs text-[#4F5052] mt-6">
                          Golf Store Number (If Applicable)*
                        </label>

                        <Field
                          type="number"
                          name="golf_store_number"
                          id="golf_store_number"
                          className="w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl"
                          placeholder="Golf Store Number"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="my-10">
                    <h4 className="text-lg tracking-wide text-red-500 pb-4">
                      Address Info
                    </h4>
                    <div className="lg:flex lg:flex-wrap">
                      <div className="lg:w-1/2 sm:w-full w-full pr-3">
                        <label className="text-xs text-[#4F5052] mt-6">
                          Address *
                        </label>
                        <Field
                          type="text"
                          name="address"
                          id="address"
                          className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl"
                          placeholder="First line address"
                        />
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                        <label className="text-xs text-[#4F5052] mt-6">
                          Country*
                        </label>
                        <Field
                          type="text"
                          name="country"
                          id="country"
                          className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl"
                          placeholder="Country"
                        />
                        <ErrorMessage
                          name="country"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                        <label className="text-xs text-[#4F5052] mt-6">
                          State*
                        </label>
                        <Field
                          type="text"
                          name="state"
                          id="state"
                          className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl"
                          placeholder="State"
                        />
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                      <div className="lg:w-1/2 sm:w-full w-full">
                        <label className="text-xs text-[#4F5052] mt-6">
                          Town/City *
                        </label>
                        <Field
                          type="text"
                          name="city"
                          id="city"
                          className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl"
                          placeholder="Location"
                        />
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                        <label className="text-xs text-[#4F5052] mt-6">
                          Zipcode*
                        </label>
                        <Field
                          type="text"
                          name="zipcode"
                          id="zipcode"
                          className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl"
                          placeholder="Area Code"
                        />
                        <ErrorMessage
                          name="zipcode"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <button
                      disabled={loading}
                      className={`${
                        !loading ? `bg-themecolor` : `bg-themecolor/40`
                      } flex px-4 py-2 rounded-lg font-light text-white text-sm mt-5`}
                    >
                      SUBMIT{loading && <Loader />}
                    </button>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default BecomeAnIntroducer;
