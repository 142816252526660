import React, { useEffect } from "react";
import { TAB1, TAB2 } from "../../assets/img";

const OverView = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sm:my-24 my-16">
      <section className="px-4 my-4 mx-auto h-full sm:flex block justify-center items-center text-white bg-black">
        <div className="p-8 md:w-1/2 w-full my-auto">
          <div className="flex flex-col text-start sm:font-normal xl:text-6xl lg:text-5xl md:text-4xl sm:text-3xl text-2xl">
            <span>D.O.P.E. GOLF</span>
            <span className="text-red-500 mt-1">TRUING COMBINE</span>
          </div>
          <div className="my-4 flex flex-wrap gap-2 text-start sm:font-normal xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
            <span>CAPTURE |</span>
            <span className="text-red-500">CALCULATE</span>
            <span>| CONFIDENCE</span>
          </div>
          <div className="lg:text-lg md:text-md sm:text-sm text-xs">
            THERE IS NO SUCH THING AS "
            <span className="text-red-500">STOCK DISTANCE</span>"
          </div>
          <div className="my-4 lg:text-lg md:text-md sm:text-sm text-xs">
            You were fitted for custom clubs. You have your own unique “stock”
            swing that produces repeatable launch parameters (Launch angle, spin
            rate, ball speed, spin axis). These launch paraments vary based on
            the loft and lie of each club
          </div>
          <div className="lg:text-lg md:text-md sm:text-sm text-xs">
            Repeatable swing = Repeatable launch parameters
          </div>
          <div className="my-4 lg:text-lg md:text-md sm:text-sm text-xs">
            Repeatable launch parameters
            <span className="text-red-500"> DOES NOT EQUAL </span>
            Repeatable carry distances
          </div>
          <div className="text-red-500">WHY?</div>
          <div className="my-4 lg:text-lg md:text-md sm:text-sm text-xs">
            ANSWER:
            <span className="text-red-500"> ATMOSPHERIC VARIABLES </span>
            (Temperature, Humidity, Barometirc Pressure, Elevation, Wind)
          </div>
          <div className="lg:text-lg md:text-md sm:text-sm text-xs">
            No matter how consistent you are, the moment the ball leaves the
            club face, the
            <span className="text-red-500"> atmospheric variables </span>
            will determine how far the ball will carry.
          </div>
          <div className="my-4 lg:text-lg md:text-md sm:text-sm text-xs">
            By collecting your average Launch Parameters with each club, the
            D.O.P.E. Golf Application will import the real time atmospherics
            based off your GPS location and compute to a 99% accuracy how far
            the ball will go, regardless of time of day, year, or location
          </div>
          <div className="lg:text-lg md:text-md sm:text-sm text-xs">
            <span className="text-red-500">CAPTURE </span>
            your stock launch parameters.
          </div>
          <div className="lg:text-lg md:text-md sm:text-sm text-xs">
            <span className="text-red-500">CALCULATE </span>
            your adjusted carry distance based off real time atmospherics
            variables.
          </div>
          <div className="lg:text-lg md:text-md sm:text-sm text-xs">
            <span className="text-red-500">CONFIDENCE </span>
            increased knowing you have selected the right club
          </div>
        </div>
        <div className="flex flex-col md:w-1/2 w-full md:min-h-[730px] sm:min-h-[530px] min-h-[400px]">
          <div className="w-full md:p-16 p-8 flex items-center">
            <img src={TAB2} />
          </div>
          <div className="flex justify-center items-center h-full lg:text-2xl md:text-xl sm:text-lg text-md">
            DATA FIELDS WE
            <span className="text-red-500 ml-1"> COLLECT</span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OverView;
