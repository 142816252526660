import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AppHeader = ({ display, setDisplay }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <header className="fixed sm:top-[60px] top-[40px] left-0 right-0 bg-slate-400 tracking-wide text-white z-30 pt-0">
        <nav className="flex flex-wrap justify-center items-center">
          <div className="menu">
            <div className="flex flex-wrap items-center">
              <div className="group relative">
                <ul className="relative top-full right-0 flex bg-transparent ">
                  <li className="cursor-pointer sm:px-8 px-2 py-2 md:text-base sm:text-sm text-xs">
                    <div
                      className={`${
                        location?.pathname === "/app" && `text-red-600`
                      }`}
                      onClick={() => navigate("/app")}
                    >
                      Overview
                    </div>
                  </li>

                  <li className="cursor-pointer sm:px-8 px-2 py-2 md:text-base sm:text-sm text-xs">
                    <div
                      className={`${
                        location?.pathname?.includes("/app/wind") &&
                        `text-red-600`
                      }`}
                      onClick={() => navigate("/app/wind")}
                    >
                      Wind Features
                    </div>
                  </li>

                  <li className="cursor-pointer sm:px-8 px-2 py-2 md:text-base sm:text-sm text-xs">
                    <div
                      className={`${
                        location?.pathname?.includes("forecast") &&
                        `text-red-600`
                      }`}
                      onClick={() => navigate("/app/forecast")}
                    >
                      Forecast Feature
                    </div>
                  </li>
                  <li className="cursor-pointer sm:px-8 px-2 py-2 md:text-base sm:text-sm text-xs">
                    <div
                      className={`${
                        location?.pathname?.includes("shot-shape") &&
                        `text-red-600`
                      }`}
                      onClick={() => navigate("/app/shot-shape")}
                    >
                      Shot Shape Feature
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default AppHeader;
