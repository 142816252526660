import React from 'react'
import OverView from './OverView';
import InstructionalVideo from './InstructionalVideo';
import { Route, Routes } from 'react-router-dom';
import LaunchMonitor from './LaunchMonitor';

const TruingCombineRoute = () => {
  return (
    <>
      <Routes>
        <Route exact index element={<OverView />} />
        <Route
          exact
          path="/instructional-video"
          element={<InstructionalVideo />}
        />
        <Route exact path="/launch-monitor" element={<LaunchMonitor />} />
      </Routes>
    </>
  );
}

export default TruingCombineRoute