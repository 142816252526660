import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import AboutHeader from "./AboutHeader";

export default function AboutLayout(props) {
  const [display, setDisplay] = useState(false);

  return (
    <>
      {/* <NavBar setDisplay={setDisplay} display={display} setModal={setModal} /> */}
      <AboutHeader setDisplay={setDisplay} display={display} />
      <div className="md:mt-[100px] sm:mt-[94px] mt-[70px]">
        <Outlet />
      </div>
    </>
  );
}
