import React, { useEffect } from "react";
import { MASK2 } from "../../assets/img";

const Wind = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sm:my-24 my-16">
      <section className="px-4 my-4 mx-auto h-full sm:flex block justify-center items-center text-white bg-black">
        <div className="p-8 md:w-1/2 w-full my-auto">
          <div className="flex flex-col text-start sm:font-normal xl:text-6xl lg:text-5xl md:text-4xl sm:text-3xl text-2xl">
            <span>D.O.P.E. GOLF</span>
            <span className="text-red-500 mt-1">WIND FEATURE</span>
          </div>

          <div className="py-4 lg:text-lg md:text-md sm:text-sm text-xs">
            Reading Wind is an art. The impact wind has on the total carry
            distance of your ball will vary depending on the direction and speed
            of the wind, the club you are hitting, the type of shot you are
            hitting (high draw/low fade etc.), and the launch parameters of the
            shot.
          </div>
          <div className="lg:text-2xl md:text-xl sm:text-lg text-md">
            The D.O.P.E. APP can help take the guess work out of it!
          </div>
          <div className="py-4 lg:text-lg md:text-md sm:text-sm text-xs">
            By utilizing the <span className="text-red-500">WIND FEATURE</span>,
            you can select the cardinal direction the wind is coming from and
            the speed at which you think the wind is blowing. The D.O.P.E. Golf
            APP will then compute how much further or shorter the ball will
            carry based on the impact of wind.
          </div>
        </div>
        <div className="md:w-1/2 w-full md:min-h-[730px] sm:min-h-[530px] min-h-[400px] md:p-16 p-8 flex items-center">
          <img src={MASK2} alt />
        </div>
      </section>
      <section className="px-4 my-4 mx-auto h-full sm:flex block justify-center items-center text-white bg-black">
        <div className="p-8 sm:w-1/2 w-full my-auto">
          <div className="flex flex-col text-start sm:font-normal xl:text-6xl lg:text-5xl md:text-4xl sm:text-3xl text-2xl">
            <span>D.O.P.E. GOLF</span>
            <span className="text-red-500 mt-1">WIND FEATURE</span>
          </div>
        </div>
        <div className="md:w-1/2 w-full lg:min-h-[700px] md:min-h-[500px] sm:min-h-[400px] min-h-[300px] md:p-8 p-4 flex items-center">
          <div className="container px-4 mx-auto max-w-6xl">
            <div className="w-full overflow-hidden sm:h-[650px] h-[250px]">
              <iframe
                className="object-contain"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/-RmUADCWI4A"
                title="All Sports Golf Battle | Dude Perfect"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Wind;
