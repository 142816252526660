import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import LazyloadLoader from "../components/common/LazyLoader";
import DefaultLayout from "../layouts/DefaultLayout";
import DashboardRoute from "../pages";

const AppRoutes = () => {
  return (
    <Suspense fallback={<LazyloadLoader />}>
      <Routes>
        <Route path="*" element={<DefaultLayout />}>
          <Route path="*" element={<DashboardRoute />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
export default AppRoutes;
