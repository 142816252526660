import React from "react";
import {
  FlagImage,
  Golf1Image,
  Golf2Image,
  Golf3Image,
  Golf4Image,
  Golf5Image,
  Golf6Image,
  Golf7Image,
  Golf8Image,
} from "../assets/img";

const NearByIntroducer = () => {
  return (
    <div>
      <main className="text-white md:pt-[60px] sm:pt-[40px]">
        <section className=" h-[50vh] bg-no-repeat w-full bg-[url('../../assets/img/icon/intro.webp')]">
          <div className="container mx-auto px-4 h-full flex flex-col justify-center items-center">
            <h1 className="lg:text-6xl sm:text-4xl text-3xl">
              Find a Golf Introducer
            </h1>
            <div className="relative justify-between xl:max-w-5xl lg:max-w-3xl sm:max-w-xl w-full xl:mt-5 mt-3">
              <a href="/#">
                {" "}
                <i className="ri-map-pin-line absolute top-1/2 -translate-y-1/2 text-xl px-8 left-0 text-black opacity-60"></i>
              </a>
              <input
                type="search"
                name=""
                id=""
                placeholder="SEARCH BY LOCATION OR INTRODUCER"
                className="block w-full rounded-lg pl-14 placeholder:text-xs lg:py-5 md:py-3 py-2 outline-none placeholder:tracking-widest text-black"
              />
            </div>
            <div className="flex  bg-[#1E1F21]/30 px-10 lg:py-4 sm:py-2 mt-0 py-1 items-center max-w-7xl backdrop-blur-sm md:text-sm text-xs rounded-lg tracking-wider">
              <a href="/#">
                {" "}
                <i className="ri-map-pin-line sm:text-xl text-lg"></i>
              </a>
              <h4 className="pl-1 ">
                FIND A INTRODUCER NEAR ME |
                <a href="/#">
                  <span className="text-tgreen px-1 font-semibold">
                    SEE ALL INTRODUCER
                  </span>
                </a>
              </h4>
            </div>
          </div>
        </section>
        <section>
          <div className="container mx-auto px-4">
            <div className="xl:grid xl:grid-cols-4 xl:gap-8 lg:grid lg:grid-cols-3 lg:gap-8 sm:grid sm:grid-cols-2 sm:gap-8 grid grid-cols-1 gap-8 sm:px-0 px-14  max-w-6xl mx-auto py-28">
              <div className="bg-[url('../../assets/img/icon/contact.webp')] bg-cover bg-center w-full h-full text-center  sm:text-left px-8 py-6">
                <div className="flex sm:justify-start rounded justify-center">
                  <img alt="" src={Golf1Image} />
                </div>
                <h3 className="text-2xl pt-6">Blake Smith</h3>
                <h4 className="text-tgreen text-[13px]  uppercase ">
                  Director of Instructor
                </h4>
                <div className="flex flex-wrap sm:justify-start justify-center items-center pt-3 opacity-50 text-[#DEDFE1] text-[15px]">
                  <i className="ri-map-pin-line text-xl"></i>
                  <h4 className="pl-1">Anchorage, AK</h4>
                </div>
                <div className="flex flex-wrap sm:justify-start justify-center  items-center pt-1 opacity-50 text-[#DEDFE1] text-[15px]">
                  <img alt="" src={FlagImage} className="text-xl " />
                  <h4 className="pl-1">Anchorage Golf Course</h4>
                </div>
              </div>
              <div className="bg-[url('../../assets/img/icon/contact.webp')] bg-cover bg-center w-full h-full text-center  sm:text-left px-8 py-6">
                <div className="flex sm:justify-start rounded justify-center">
                  <img alt="" src={Golf2Image} />
                </div>
                <h3 className="text-2xl pt-6">Kevin Perkins</h3>
                <h4 className="text-tgreen text-[13px]  uppercase ">
                  Master Professional
                </h4>
                <div className="flex flex-wrap sm:justify-start justify-center items-center pt-3 opacity-50 text-[#DEDFE1] text-[15px]">
                  <i className="ri-map-pin-line text-xl"></i>
                  <h4 className="pl-1">Anchorage, AK</h4>
                </div>
                <div className="flex flex-wrap sm:justify-start justify-center items-center pt-1 opacity-50 text-[#DEDFE1] text-[15px]">
                  <img alt="" src={FlagImage} className="text-xl " />
                  <h4 className="pl-1">Anchorage Golf Course</h4>
                </div>
              </div>
              <div className="bg-[url('../../assets/img/icon/contact.webp')] bg-cover bg-center w-full h-full text-center  sm:text-left px-8 py-6">
                <div className="flex sm:justify-start rounded justify-center">
                  <img alt="" src={Golf3Image} />
                </div>
                <h3 className="text-2xl pt-6">Michael Dugas</h3>
                <h4 className="text-tgreen text-[13px]  uppercase ">
                  Head Golf Professional
                </h4>
                <div className="flex flex-wrap sm:justify-start justify-center items-center pt-3 opacity-50 text-[#DEDFE1] text-[15px]">
                  <i className="ri-map-pin-line text-xl"></i>
                  <h4 className="pl-1">Anchorage, AK</h4>
                </div>
                <div className="flex flex-wrap sm:justify-start justify-center items-center pt-1 opacity-50 text-[#DEDFE1] text-[15px]">
                  <img alt="" src={FlagImage} className="text-xl " />
                  <h4 className="pl-1">Anchorage Golf Course</h4>
                </div>
              </div>
              <div className="bg-[url('../../assets/img/icon/contact.webp')] bg-cover bg-center w-full h-full text-center  sm:text-left px-8 py-6">
                <div className="flex sm:justify-start rounded justify-center">
                  <img alt="" src={Golf4Image} />
                </div>
                <h3 className="text-2xl pt-6">Donald Roberts</h3>
                <h4 className="text-tgreen text-[13px]  uppercase ">
                  Golf Professional
                </h4>
                <div className="flex flex-wrap sm:justify-start justify-center items-center pt-3 opacity-50 text-[#DEDFE1] text-[15px]">
                  <i className="ri-map-pin-line text-xl"></i>
                  <h4 className="pl-1">Anchorage, AK</h4>
                </div>
                <div className="flex flex-wrap sm:justify-start justify-center items-center pt-1 opacity-50 text-[#DEDFE1] text-[15px]">
                  <img alt="" src={FlagImage} className="text-xl" />
                  <h4 className="pl-1">Anchorage Golf Course</h4>
                </div>
              </div>
              <div className="bg-[url('../../assets/img/icon/contact.webp')] bg-cover bg-center w-full h-full text-center  sm:text-left px-8 py-6">
                <div className="flex sm:justify-start rounded justify-center">
                  <img alt="" src={Golf5Image} />
                </div>
                <h3 className="text-2xl pt-6">Christopher Doh..</h3>
                <h4 className="text-tgreen text-[13px]  uppercase ">
                  Director of Instructor
                </h4>
                <div className="flex flex-wrap sm:justify-start justify-center items-center pt-3 opacity-50 text-[#DEDFE1] text-[15px]">
                  <i className="ri-map-pin-line text-xl"></i>
                  <h4 className="pl-1">Anchorage, AK</h4>
                </div>
                <div className="flex flex-wrap sm:justify-start justify-center items-center pt-1 opacity-50 text-[#DEDFE1] text-[15px]">
                  <img alt="" src={FlagImage} className="text-xl " />
                  <h4 className="pl-1">Anchorage Golf Course</h4>
                </div>
              </div>
              <div className="bg-[url('../../assets/img/icon/contact.webp')] bg-cover bg-center w-full h-full text-center  sm:text-left px-8 py-6">
                <div className="flex sm:justify-start rounded justify-center">
                  <img alt="" src={Golf6Image} />
                </div>
                <h3 className="text-2xl pt-6">Ashton Benn</h3>
                <h4 className="text-tgreen text-[13px]  uppercase ">
                  Master Professional
                </h4>
                <div className="flex flex-wrap sm:justify-start justify-center items-center pt-3 opacity-50 text-[#DEDFE1] text-[15px]">
                  <i className="ri-map-pin-line text-xl"></i>
                  <h4 className="pl-1">Anchorage, AK</h4>
                </div>
                <div className="flex flex-wrap sm:justify-start justify-center items-center pt-1 opacity-50 text-[#DEDFE1] text-[15px]">
                  <img alt="" src={FlagImage} className="text-xl " />
                  <h4 className="pl-1">Anchorage Golf Course</h4>
                </div>
              </div>
              <div className="bg-[url('../../assets/img/icon/contact.webp')] bg-cover bg-center w-full h-full text-center  sm:text-left px-8 py-6">
                <div className="flex sm:justify-start rounded justify-center">
                  <img alt="" src={Golf7Image} />
                </div>
                <h3 className="text-2xl pt-6">Harold Balboni</h3>
                <h4 className="text-tgreen text-[13px]  uppercase ">
                  Head Golf Professional
                </h4>
                <div className="flex flex-wrap sm:justify-start justify-center items-center pt-3 opacity-50 text-[#DEDFE1] text-[15px]">
                  <i className="ri-map-pin-line text-xl"></i>
                  <h4 className="pl-1">Anchorage, AK</h4>
                </div>
                <div className="flex flex-wrap sm:justify-start justify-center items-center pt-1 opacity-50 text-[#DEDFE1] text-[15px]">
                  <img alt="" src={FlagImage} className="text-xl " />
                  <h4 className="pl-1">Anchorage Golf Course</h4>
                </div>
              </div>
              <div className="bg-[url('../../assets/img/icon/contact.webp')] bg-cover bg-center w-full h-full text-center  sm:text-left px-8 py-6">
                <div className="flex sm:justify-start rounded justify-center">
                  <img alt="" src={Golf8Image} />
                </div>
                <h3 className="text-2xl pt-6">Jay Durfee</h3>
                <h4 className="text-tgreen text-[13px]  uppercase ">
                  Golf Professional
                </h4>
                <div className="flex flex-wrap sm:justify-start justify-center items-center pt-3 opacity-50 text-[#DEDFE1] text-[15px]">
                  <i className="ri-map-pin-line text-xl"></i>
                  <h4 className="pl-1">Anchorage, AK</h4>
                </div>
                <div className="flex flex-wrap sm:justify-start justify-center items-center pt-1 opacity-50 text-[#DEDFE1] text-[15px]">
                  <img alt="" src={FlagImage} className="text-xl " />
                  <h4 className="pl-1">Anchorage Golf Course</h4>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default NearByIntroducer;
