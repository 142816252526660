import { ErrorMessage, Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { HideIcon, ViewIcon, CloseIcon, Golf1Image } from "../assets/img";
import Loader from "../components/Loader";
import authStore from "../stores/auth";
import { ACCESS_TOKEN } from "../utils/constants";
import CustomSpinner from "../components/customSpinner";

const Account = () => {
  const [display, setDisplay] = useState(false);
  const [dLogoutModal, setDLogoutModal] = useState(false);
  const [resetPWDModal, setResetPWDModal] = useState(false);
  const [dCancelModal, setDCancelModal] = useState(false);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    getUserDetailAPI,
    resetPWDApi,
    getUserData,
    cancelSubscriptionApi,
    loading,
  } = authStore((state) => ({
    getUserDetailAPI: state.getUserDetailAPI,
    resetPWDApi: state.resetPWDApi,
    getUserData: state.getUserData?.data,
    cancelSubscriptionApi: state.cancelSubscriptionApi,
    loading: state.loading,
  }));

  useEffect(() => {
    localStorage.getItem(ACCESS_TOKEN) && getUserDetailAPI();
  }, [getUserDetailAPI]);

  const clickOnLogout = () => {
    localStorage.clear();
    setModal(false);
    navigate("/");
    window.location.reload(false);
  };

  const date1 = new Date(
    moment.unix(getUserData?.payment?.period?.start).format("MM/DD/yyyy")
  );
  const date2 = new Date(
    moment.unix(getUserData?.payment?.period?.end).format("MM/DD/yyyy")
  );
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  const cancelModal = (
    <>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="fixed inset-0 w-full h-full backdrop-blur-sm bg-black/50" />
        <div className="flex items-center min-h-screen px-4 py-8 cursor-pointer">
          <div className="relative w-full max-w-md mx-auto">
            <div className="bg-[#192035] rounded-lg">
              <div className="flex justify-between px-5 pt-5 pb-2">
                <h1 className="text-themecolor text-center text-xl my-auto">{`Subscription Conformation`}</h1>
              </div>
              <div className="flex justify-center w-full">
                <div className="w-full px-5">
                  <div className="text-white py-2 rounded-lg">
                    {`Are you sure you want to cancel subscription?`}
                  </div>
                  <div className="flex justify-end mt-2">
                    <div className="flex justify-end">
                      <button
                        className={`bg-transparent border border-1 border-slate-500 flex px-4 py-2 rounded-lg font-light text-slate-500 text-sm my-3`}
                        onClick={() => setDCancelModal(false)}
                      >
                        CANCEL
                      </button>
                      <button
                        className={`bg-themecolor ml-3 flex px-4 py-2 rounded-lg font-light text-white text-sm my-3`}
                        onClick={() => {
                          cancelSubscriptionApi({
                            data: getUserData?.payment?.subscription_id,
                            setDCancelModal: setDCancelModal,
                          }).then(
                            (res) => res.status === 200 && getUserDetailAPI()
                          );
                        }}
                      >
                        CANCEL SUBSCRIPTION {loading && <Loader />}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const logoutModal = (
    <>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="fixed inset-0 w-full h-full backdrop-blur-sm bg-black/50" />
        <div className="flex items-center min-h-screen px-4 py-8 cursor-pointer">
          <div className="relative w-full max-w-md mx-auto">
            <div className="bg-[#192035] rounded-lg">
              <div className="flex justify-between px-5 pt-5 pb-2">
                <h1 className="text-themecolor text-center text-xl my-auto">{`Logout Conformation`}</h1>
              </div>
              <div className="flex justify-center w-full">
                <div className="w-full px-5">
                  <div className="text-white py-2 rounded-lg">
                    {`Are you sure you want to logout?`}
                  </div>
                  <div className="flex justify-end mt-2">
                    <div className="flex justify-end">
                      <button
                        className={`bg-transparent border border-1 border-slate-500 flex px-4 py-2 rounded-lg font-light text-slate-500 text-sm my-3`}
                        onClick={() => setDLogoutModal(false)}
                      >
                        CANCEL
                      </button>
                      <button
                        className={`bg-themecolor ml-3 flex px-4 py-2 rounded-lg font-light text-white text-sm my-3`}
                        onClick={() => {
                          clickOnLogout();
                          setDLogoutModal(false);
                        }}
                      >
                        LOG OUT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  const resetmainPWDModal = (
    <>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="fixed inset-0 w-full h-full backdrop-blur-sm bg-black/50" />
        <div className="flex items-center min-h-screen px-4 py-8 cursor-pointer">
          <div className="relative w-full max-w-md mx-auto">
            <div className="bg-[#192035] rounded-lg">
              <div className="flex justify-between px-5 pt-5 pb-2">
                <h1 className="text-themecolor text-center text-xl my-auto">{`Reset Password`}</h1>
              </div>
              <div className="flex justify-center w-full">
                <div className="w-full px-5">
                  <div className="text-white py-2 rounded-lg">
                    <Formik
                      initialValues={{
                        conformPassword: "",
                        newPassword: "",
                        password: "",
                      }}
                      validationSchema={Yup.object().shape({
                        password: Yup.string()
                          .required("Password is required")
                          .min(3, "Password must be at 3 char long"),
                        newPassword: Yup.string()
                          .required("New Password is required")
                          .min(3, "New Password must be at 3 char long"),
                        conformPassword: Yup.string()
                          .required("Confirm Password is required")
                          .oneOf(
                            [Yup.ref("newPassword")],
                            "Confirm Passwords does not match"
                          ),
                      })}
                      onSubmit={(fields) => {
                        resetPWDApi(fields, navigate).then(
                          (res) =>
                            res?.status === 200 && setResetPWDModal(false)
                        );
                      }}
                      render={({ errors, status, touched }) => (
                        <Form className="w-full h-full">
                          {/* {loading && <CustomSpinner />} */}
                          <div className="mx-auto ">
                            <div className="mt-2 flex flex-col">
                              <label
                                className="text-gray-500 py-2 pt-3 text-xs"
                                htmlFor="password"
                              >
                                Password
                              </label>
                              <Field
                                className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-1 mb-1"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                id="password"
                                placeholder="Password"
                              />
                              <div className="relative">
                                <div className="absolute right-2 top-[-18px] transform -translate-y-1/2">
                                  {showPassword ? (
                                    <img
                                      src={ViewIcon}
                                      alt="view_icon"
                                      className="h-5 w-5 cursor-pointer"
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={HideIcon}
                                      alt="hide_icon"
                                      className="h-5 w-5 cursor-pointer"
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="text-red-500 text-xs"
                              />
                            </div>
                            <div className="mt-2 flex flex-col">
                              <label
                                className="text-gray-500 py-2 pt-3 text-xs"
                                htmlFor="newPassword"
                              >
                                New password
                              </label>
                              <Field
                                className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-1 mb-1"
                                type={showNewPassword ? "text" : "password"}
                                name="newPassword"
                                id="newPassword"
                                placeholder="New password"
                              />
                              <div className="relative">
                                <div className="absolute right-2 top-[-18px] transform -translate-y-1/2">
                                  {showNewPassword ? (
                                    <img
                                      src={ViewIcon}
                                      alt="view_icon"
                                      className="h-5 w-5 cursor-pointer"
                                      onClick={() =>
                                        setShowNewPassword(!showNewPassword)
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={HideIcon}
                                      alt="hide_icon"
                                      className="h-5 w-5 cursor-pointer"
                                      onClick={() =>
                                        setShowNewPassword(!showNewPassword)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <ErrorMessage
                                name="newPassword"
                                component="div"
                                className="text-red-500 text-xs"
                              />
                            </div>
                            <div className="mt-2 flex flex-col">
                              <label
                                className="text-gray-500 py-2 pt-3 text-xs"
                                htmlFor="conformPassword"
                              >
                                Confirm Password
                              </label>
                              <Field
                                className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-1 mb-1"
                                type={showConfirmPassword ? "text" : "password"}
                                name="conformPassword"
                                id="conformPassword"
                                placeholder="Confirm Password"
                              />
                              <div className="relative">
                                <div className="absolute right-2 top-[-18px] transform -translate-y-1/2">
                                  {showConfirmPassword ? (
                                    <img
                                      src={ViewIcon}
                                      alt="view_icon"
                                      className="h-5 w-5 cursor-pointer"
                                      onClick={() =>
                                        setShowConfirmPassword(
                                          !showConfirmPassword
                                        )
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={HideIcon}
                                      alt="hide_icon"
                                      className="h-5 w-5 cursor-pointer"
                                      onClick={() =>
                                        setShowConfirmPassword(
                                          !showConfirmPassword
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <ErrorMessage
                                name="conformPassword"
                                component="div"
                                className="text-red-500 text-xs"
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="flex justify-end mt-2">
                              <div className="flex justify-end">
                                <button
                                  className={`bg-transparent border border-1 border-slate-500 flex px-4 py-2 rounded-lg font-light text-slate-500 text-sm my-3`}
                                  onClick={() => setResetPWDModal(false)}
                                >
                                  CANCEL
                                </button>
                                <button
                                  type="submit"
                                  disabled={loading}
                                  className={`${
                                    !loading
                                      ? `bg-themecolor`
                                      : `bg-themecolor/40`
                                  } ml-3 flex px-4 py-2 rounded-lg font-light text-white text-sm my-3`}
                                >
                                  SUBMIT{loading && <Loader />}
                                </button>
                              </div>
                            </div>
                            {/* <button
                              disabled={loading}
                              className={`${
                                !loading ? `bg-themecolor` : `bg-themecolor/40`
                              } flex px-4 py-2 rounded-lg font-light text-white text-sm mt-5`}
                            >
                              SUBMIT
                            </button> */}
                          </div>
                        </Form>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div>
      {resetPWDModal && resetmainPWDModal}
      {dLogoutModal && logoutModal}
      {dCancelModal && cancelModal}
      {loading && <CustomSpinner />}
      <main className="h-screen flex justify-center pt-[80px] bg-[url('../../assets/img/icon/contact.webp')]">
        <section className="flex justify-center w-full h-full my-auto contact relative after:content-[''] after:w-full after:absolute after:top-0 after:left-0 after:bg-origin-border after:object-cover after:bg-center after:h-full after:bg-cover after:z-[-1] ">
          <div className="container px-4 mx-auto">
            <div className="py-10 max-w-4xl mx-auto relative">
              <div>
                <h2 className="text-white text-3xl xl:text-5xl max-w-md sm:text-3xl md:text-5xl  lg:leading-tight xl:leading-tight">
                  My Account
                </h2>
                <div className="">
                  <div className="py-6 mb-5 flex">
                    <h2 className="text-white xl:text-4xl  xl:leading-tight sm:text-2xl text-2xl md:text-4xl opacity-25">
                      Details
                    </h2>
                  </div>
                </div>
                <div className="w-full h-full">
                  <div className="mx-auto py-8">
                    <div className="flex justify-center px-5">
                      <div className="w-full text-white">
                        <div className="flex sm:flex-row flex-col justify-between mb-5">
                          <div className="flex w-full sm:justify-start justify-center">
                            <div className="h-full my-auto flex flex-col justify-center">
                              <div className="flex sm:justify-start justify-center w-36 h-36 rounded-full">
                                <img
                                  height="100%"
                                  width="100%"
                                  alt=""
                                  src={getUserData?.image || Golf1Image}
                                  className="rounded-full"
                                />
                              </div>
                            </div>
                            <div className="my-auto ml-8 ">
                              <h3 className="text-2xl">{`${
                                getUserData?.firstName || ""
                              } ${getUserData?.lastName || ""}`}</h3>
                              <h6 className="text-slate-500 text-sm mt-0 w-[200px] truncate">
                                {getUserData?.email || ""}
                              </h6>

                              <div className="flex mt-4">
                                <h6
                                  onClick={() => setDLogoutModal(true)}
                                  className="cursor-pointer text-red-500 text-[13px] mt-0 truncate"
                                >
                                  <u>Logout</u>
                                </h6>

                                <h6 className="text-slate-500 text-[13px] px-2 mt-0 truncate">
                                  |
                                </h6>
                                <h6
                                  onClick={() => setResetPWDModal(true)}
                                  className="cursor-pointer text-white text-[13px] mt-0 truncate"
                                >
                                  <u>Reset password?</u>
                                </h6>
                              </div>
                              {getUserData?.payment?.subscription_id ? (
                                <>
                                  <div className="flex justify-start">
                                    <div
                                      className={`cursor-pointer mt-4 bg-themecolor text-white px-4 py-2 font-thin rounded-lg text-sm`}
                                    >
                                      <a
                                        href="https://play.google.com/store/search?q=dope%20golf&c=apps"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        GET APPLICATION
                                      </a>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="flex justify-start">
                                    <div
                                      onClick={() => {
                                        setModal(false);
                                        navigate(`/register/s1xuuy5ci7ty8iw9`);
                                      }}
                                      className={`cursor-pointer mt-4 bg-themecolor text-white px-4 py-2 font-thin rounded-lg text-sm`}
                                    >
                                      PURCHASE SUBSCRIPTION
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          {getUserData?.payment?.subscription_id ? (
                            <div className="my-auto sm:mt-0 mt-8 sm:w-72 w-full">
                              <div className="rounded-lg text-center plan cursor-pointer">
                                <h3 className="bg-[#4F5052] py-3 border-2 border-hidden text-sm font-thin border-[#FFAE12] tracking-widest rounded-t-lg">
                                  {getUserData?.payment?.plan || "BASIC PLAN"}
                                </h3>
                                <h4 className="text-2xl text-tgreen pt-4">
                                  {getUserData?.payment?.price || "10"}
                                  <span className="text-xl">$</span>
                                </h4>
                                <h5 className="font-thin text-sm">Per month</h5>
                                <div className="flex justify-center">
                                  <div
                                    onClick={() => setDCancelModal(true)}
                                    target="_blank"
                                    className={`mt-4 bg-white text-themecolor sm:px-4 px-3  sm:py-2 py-1 font-thin rounded-lg sm:text-sm text-xs`}
                                    rel="noreferrer"
                                  >
                                    CANCEL SUBSCRIPTION
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        {/* {getUserData?.payment?.subscription_id ? (
                          <div>
                            <hr className="border border-t-0 border-slate-400" />
                            <div className="flex justify-between my-3">
                              <div className="flex">
                                <h3 className="text-slate-500 text-start sm:text-xl text-sm">{`Remaining Days : `}</h3>
                                <h3 className="ml-2 text-themecolor text-start sm:text-xl text-sm">{` ${
                                  diffDays + " days"
                                }`}</h3>
                              </div>

                              <button
                                onClick={() => setDCancelModal(true)}
                                className={`border border-1 border-themecolor text-themecolor px-3 py-1.5 font-thin rounded-lg sm:text-[10px] text-[8px] my-auto`}
                              >
                                CANCEL SUBSCRIPTION
                              </button>
                            </div>
                            <hr className="border border-t-0 border-slate-400" />
                            <div className="flex justify-between my-3">
                              <div className="flex">
                                <h3 className="text-slate-500 text-start sm:text-xl text-sm">{`Download Application : `}</h3>
                              </div>

                              <button
                                className={`border border-1 border-themecolor text-themecolor px-3 py-1.5 font-thin rounded-lg sm:text-[10px] text-[8px] my-auto`}
                              >
                                <a
                                  href="https://play.google.com/store/search?q=dope%20golf&c=apps"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Get Application
                                </a>
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="w-full flex mb-5 justify-center items-center">
                            <button
                              onClick={() => {
                                setModal(false);
                                navigate(`/register/s1xuuy5ci7ty8iw9`);
                              }}
                              className={`border border-1 border-themecolor text-themecolor px-3 py-1.5 font-normal rounded-lg sm:text-[10px] text-[8px] my-auto`}
                            >
                              PURCHASE SUBSCRIPTION
                            </button>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Account;
