import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import TruingCombineHeader from "./TruingCombineHeader";

export default function TruingCombineLayout(props) {
  const [display, setDisplay] = useState(false);

  return (
    <div className="bg-black">
      {/* <NavBar setDisplay={setDisplay} display={display} setModal={setModal} /> */}
      <TruingCombineHeader setDisplay={setDisplay} display={display} />
      <div className="md:mt-[100px] sm:mt-[94px] mt-[70px]">
        <Outlet />
      </div>
    </div>
  );
}
