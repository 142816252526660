import React, { useEffect } from "react";

const LaunchMonitor = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sm:my-24 my-16">
      <section className="px-4 my-4 mx-auto h-full sm:flex block justify-center items-center text-white bg-black">
        <div className="p-8 md:w-1/2 w-full my-auto">
          <div className="flex flex-col text-start sm:font-normal xl:text-6xl lg:text-5xl md:text-4xl sm:text-3xl text-2xl">
            <span>D.O.P.E. GOLF</span>
            <span className="text-red-500 mt-1">TRUING</span>
            <span className="text-red-500 mt-1">COMBINE</span>
            <span className="text-red-500 mt-1">
              TRACKMAN <span className="text-white"> SET UP </span>
            </span>
          </div>
        </div>
        <div className="md:w-1/2 w-full md:min-h-[730px] sm:min-h-[530px] min-h-[400px] ">
          <div className="text-center md:mt-4 mt-0">
            <span className="text-red-500 my-5 xl:text-2xl lg:text-xl md:text-lg sm:text-lg text-lg">
              Trackman{" "}
              <span className="text-white"> Launch Monitor Set Up </span>
            </span>
          </div>
          <div className="px-10 py-8">
            <ol>
              <li>1. Click on Settings</li>
              <li>2. General</li>
              <li>3. Units</li>
              <li>4. Select:</li>
              <ol className="ml-14">
                <li>1. General Unit system: mph, yds/f</li>
                <li>2. Normalization Altitude Unit: Feet</li>
                <li>3. Normalization Temperature Unit: degrees Fahrenheit</li>
              </ol>
              <li>5. {`< General`}</li>
              <li>6. Normalization</li>
              <ol className="ml-14">
                <li>
                  1. Altitude: Set at your locations altitude in relation to
                  mean sea level
                </li>
                <li className="ml-14">1. Google your location altitude</li>
                <li className="ml-28">1. Example: Pinehurst elevation</li>
                <li>
                  2. Temperature: Set current temperature at your location.
                </li>
              </ol>
              <li>7. {`< General`}</li>
              <li>8. {`< Settings`}</li>
              <li>9. {`Ball:`}</li>
              <ol className="ml-14">
                <li>1. Limited Flight Soft ( For most Range Balls)</li>
                <li>
                  2. Premium Hard ( For improved range balls like Titleist
                  Practice balls)
                </li>
              </ol>
              <li>10. {`Select OK after selecting proper ball`}</li>
              <li>11. {`< Back`}</li>
              <li>12. {`Turn Normalization ON`}</li>
              <li>13. {`Arrange Data Fields to show:`}</li>
              <ol className="ml-14">
                <li>1. Launch Angle</li>
                <li>2. Spin Rate</li>
                <li>3. Ball Speed</li>
                <li>4. Initial Direction</li>
                <li>5. Carry</li>
              </ol>
            </ol>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LaunchMonitor;
