import React from "react";
import OverView from "./OverView";
import { Route, Routes } from "react-router-dom";
import AnnualIntroducer from "./AnnualIntroducer";
import BecomeAnIntroducer from "./BecomeAnIntroducer";

const IntroducerRoute = () => {
  return (
    <>
      <Routes>
        <Route exact index element={<OverView />} />
        <Route
          exact
          path="/become-introducer"
          element={<BecomeAnIntroducer />}
        />
        <Route exact path="/annual-introducer" element={<AnnualIntroducer />} />
      </Routes>
    </>
  );
};

export default IntroducerRoute;
