import { Route, Routes } from "react-router-dom";
import AboutLayout from "../layouts/AboutLayout";
import AppLayout from "../layouts/AppLayout";
import IntroducerLayout from "../layouts/IntroducerLayout";
import TruingCombineLayout from "../layouts/TruingCombineLayout";
import AboutRoute from "./about";
import Account from "./Account";
import AppRoute from "./app";
import BecomeIntroducer from "./BecomeIntroducer";
import Error from "./Error";
import Faq from "./Faq";
import Home from "./Home";
import IntroducerRoute from "./introducer";
import Login from "./Login";
import NearByIntroducer from "./NearByIntroducer";
import Pricing from "./Pricing";
import PrivacyPolicy from "./PrivacyPolicy";
import Register from "./Register";
import TermsCondition from "./TermsCondition";
import TruingCombineRoute from "./truingCombine";

const DashboardRoute = () => {
  const accessToken = localStorage.getItem("accessToken");

  return (
    <>
      <Routes>
        <Route exact index element={<Home />} />
        <Route path="/" element={<AppLayout />}>
          <Route path="app/*" element={<AppRoute />} />
        </Route>
        <Route path="/" element={<TruingCombineLayout />}>
          <Route path="truing-combine/*" element={<TruingCombineRoute />} />
        </Route>
        <Route path="/" element={<IntroducerLayout />}>
          <Route path="introducer/*" element={<IntroducerRoute />} />
        </Route>
        <Route path="/" element={<AboutLayout />}>
          <Route path="about/*" element={<AboutRoute />} />
        </Route>
        <Route exact path="/near-by" element={<NearByIntroducer />} />
        <Route exact path="/terms-condition" element={<TermsCondition />} />
        <Route exact path="/pricing" element={<Pricing />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/become-introducer" element={<BecomeIntroducer />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/account" element={<Account />} />
        {!accessToken && <Route exact path="/login" element={<Login />} />}
        <Route exact path="/register/:id" element={<Register />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
};

export default DashboardRoute;
