import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import React from "react";
import CheckoutForm from "./CheckoutForm";

loadStripe.setLoadParameters({ advancedFraudSignals: false });

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PaymentOptions = ({
  coupon,
  priceId,
  setDisplayModal,
  userId,
  setPaymentSuccessModal,
}) => {
  return (
    <>
      <Elements
        stripe={stripePromise}
        setDisplayModal={setDisplayModal}
        setPaymentSuccessModal={setPaymentSuccessModal}
        priceId={priceId}
        userId={userId}
        coupon={coupon}
      >
        <CheckoutForm
          setPaymentSuccessModal={setPaymentSuccessModal}
          setDisplayModal={setDisplayModal}
          priceId={priceId}
          userId={userId}
          coupon={coupon}
        />
      </Elements>
    </>
  );
};

export default PaymentOptions;
