import React from "react";
import { Route, Routes } from "react-router-dom";
import ForeCast from "./ForeCast";
import OverView from "./OverView";
import ShotShape from "./ShotShape";
import Wind from "./Wind";

const AppRoute = () => {
  return (
    <>
      <Routes>
        <Route exact index element={<OverView />} />
        <Route exact path="/wind" element={<Wind />} />
        <Route exact path="/forecast" element={<ForeCast />} />
        <Route exact path="/shot-shape" element={<ShotShape />} />
      </Routes>
    </>
  );
};

export default AppRoute;
