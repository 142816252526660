import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CloseIcon, WhiteLogo } from "../assets/img";

const NavBar = ({ display, setDisplay, setModal }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const changeDisplay = () => {
    setDisplay(!display);
  };
  return (
    display && (
      <>
        <div
          className="h-screen w-full fixed z-50 overflow-auto bg-black/40 "
          onClick={() => changeDisplay()}
        ></div>
        <div className="h-screen fixed z-50 overflow-auto w-[200px] bg-black text-white">
          <div className="flex flex-wrap items-center border-b border-solid border-1 border-gray-100/30">
            <div className="w-full p-5 flex justify-between">
              <img
                src={WhiteLogo}
                className="block sm:w-[129px] w-20 cursor-pointer"
                alt=""
              />
              <img
                alt=""
                src={CloseIcon}
                className="w-3 h-3 my-auto m-2"
                onClick={() => changeDisplay()}
              />
            </div>
          </div>

          <ul className="w-full cursor-pointer">
            <li
              onClick={() => {
                navigate("/");
                changeDisplay();
              }}
              className={`px-4 py-3 text-base w-full rounded ${
                location?.pathname === "/" ? `bg-red-500` : ``
              }`}
            >
              <div>Home</div>
            </li>
            {localStorage.getItem("accessToken") ? (
              <li
                onClick={() => {
                  navigate("/account");
                  changeDisplay();
                }}
                className={`px-4 py-3 text-base w-full rounded ${
                  location?.pathname === "/account" ? `bg-red-500` : ``
                }`}
              >
                <div>Account</div>
              </li>
            ) : (
              <li
                onClick={() => {
                  navigate("/login");
                  changeDisplay();
                }}
                className={`px-4 py-3 text-base w-full rounded ${
                  location?.pathname?.includes("login") ? `bg-red-500` : ``
                }`}
              >
                <div>Login</div>
              </li>
            )}

            <li
              onClick={() => {
                navigate("/app");
                changeDisplay();
              }}
              className={`px-4 py-3 text-base w-full rounded ${
                location?.pathname?.includes("app") ? `bg-red-500` : ``
              }`}
            >
              <div>D.O.P.E Golf App</div>
            </li>
            <li
              onClick={() => {
                navigate("/truing-combine");
                changeDisplay();
              }}
              className={`px-4 py-3 text-base w-full rounded ${
                location?.pathname?.includes("truing-combine")
                  ? `bg-red-500`
                  : ``
              }`}
            >
              <div>Truing Combine</div>
            </li>
            <li
              onClick={() => {
                navigate("/introducer");
                changeDisplay();
              }}
              className={`px-4 py-3 text-base w-full rounded ${
                location?.pathname?.includes("introducer") ? `bg-red-500` : ``
              }`}
            >
              <div>Become an introducer</div>
            </li>
            <li
              onClick={() => {
                navigate("/about");
                changeDisplay();
              }}
              className={`px-4 py-3 text-base w-full rounded ${
                location?.pathname?.includes("about") ? `bg-red-500` : ``
              }`}
            >
              <div>About</div>
            </li>
            <li
              onClick={() => {
                navigate("/terms-condition");
                changeDisplay();
              }}
              className={`px-4 py-3 text-base w-full rounded ${
                location?.pathname?.includes("terms-condition")
                  ? `bg-red-500`
                  : ``
              }`}
            >
              <div>Terms</div>
            </li>
            <li
              onClick={() => {
                navigate("/pricing");
                changeDisplay();
              }}
              className={`px-4 py-3 text-base w-full rounded ${
                location?.pathname?.includes("pricing") ? `bg-red-500` : ``
              }`}
            >
              <div>Pricing</div>
            </li>
            <li
              onClick={() => {
                navigate("/faq");
                changeDisplay();
              }}
              className={`px-4 py-3 text-base w-full rounded ${
                location?.pathname?.includes("faq") ? `bg-red-500` : ``
              }`}
            >
              <div>FAQs</div>
            </li>
          </ul>
        </div>
      </>
    )
  );
};

export default NavBar;
