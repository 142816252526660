import React from "react";
import { useEffect } from "react";
import { MASK1 } from "../../assets/img";

const OverView = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sm:my-24 my-16 ">
      <section className="px-4 my-4 mx-auto h-full sm:flex block justify-center items-center text-white bg-black">
        <div className="p-8 md:w-1/2 w-full my-auto">
          <div className="flex flex-col text-start sm:font-normal xl:text-6xl lg:text-5xl md:text-4xl sm:text-3xl text-2xl">
            <span>D.O.P.E. GOLF</span>
            <span className="text-red-500 mt-1">APPLICATION</span>
          </div>
          <div className="my-8 flex flex-wrap gap-2 text-start sm:font-normal xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
            <span>YOUR GAME |</span>
            <span className="text-red-500">YOUR DATA</span>
            <span>| ANYTIME |</span>
            <span className="text-red-500">ANYWHERE</span>
          </div>
          <span className="lg:text-lg md:text-md sm:text-sm text-xs">
            By collecting your average launch parameters for each club by
            utilizing a launch monitor (launch angle, spin rate, ball speed,
            spin axis), the D.O.P.E. Golf app will import the real time
            atmospheric variables based off your GPS location (temperature,
            humidity, barometric pressure, elevation, and wind). The D.O.P.E.
            Golf Ballistic Algorithm will then compute to a 99% accuracy how far
            your ball will carry if you can recreate that same swing, regardless
            of changes in atmospheric variables.
          </span>
        </div>
        <div className="md:w-1/2 w-full md:min-h-[730px] sm:min-h-[530px] min-h-[400px] md:p-16 p-8 flex items-center">
          <img src={MASK1} alt/>
        </div>
      </section>
      <section className="px-4 my-4 mx-auto h-full sm:flex block justify-center items-center text-white bg-black">
        <div className="p-8 md:w-1/2 w-full my-auto">
          <div className="flex flex-col text-start sm:font-normal xl:text-6xl lg:text-5xl md:text-4xl sm:text-3xl text-2xl">
            <span>D.O.P.E. GOLF</span>
            <span className="text-red-500 mt-1">APPLICATION</span>
          </div>
          <div className="my-8 flex flex-wrap gap-2 text-start font-light xl:text-xl lg:text-lg md:text-md sm:text-sm text-xs">
            <span>YOUR GAME |</span>
            <span className="text-red-500">YOUR DATA</span>
            <span>| ANYTIME |</span>
            <span className="text-red-500">ANYWHERE</span>
          </div>
        </div>
        <div className="md:w-1/2 w-full lg:min-h-[700px] md:min-h-[500px] sm:min-h-[400px] min-h-[300px] md:p-8 p-4 flex items-center">
          <div className="container px-4 mx-auto max-w-6xl">
            <div className="w-full overflow-hidden sm:h-[650px] h-[250px]">
              <iframe
                className="object-contain"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/-RmUADCWI4A"
                title="All Sports Golf Battle | Dude Perfect"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OverView;
