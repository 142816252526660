import SearchIcon from "./icon/search.svg";
import WhiteLogo from "./icon/white-logo.png";
import PersonIcon from "./icon/person.png";
import InstaIcon from "./icon/insta.png";
import Layer1Image from "./icon/layer1.png";
import Layer2Image from "./icon/layer2.png";
import Testimonal1 from "./icon/testimonal1.png";
import Testimonal2 from "./icon/testimonial2.png";
import Testimonal3 from "./icon/testimonial3.png";
import ContactImage from "./icon/contact.webp";
import IntroImage from "./icon/intro.webp";
import FlagImage from "./icon/flag.png";
import Golf1Image from "./icon/golf1.png";
import Golf2Image from "./icon/golf2.png";
import Golf3Image from "./icon/golf3.png";
import Golf4Image from "./icon/golf4.png";
import Golf5Image from "./icon/golf5.png";
import Golf6Image from "./icon/golf6.png";
import Golf7Image from "./icon/golf7.png";
import Golf8Image from "./icon/golf8.png";
import Partner1Image from "./icon/partner1.png";
import Partner2Image from "./icon/partner2.png";
import Partner3Image from "./icon/partner3.png";
import Partner4Image from "./icon/partner4.png";
import Partner5Image from "./icon/partner5.png";
import Partner6Image from "./icon/partner6.png";
import MenuIcon from "./icon/menu.png";
import CloseIcon from "./icon/close.png";
import StripeIcon from "./icon/hostiso-stripe.svg";
import SuccessIcon from "./icon/checked.png";
import DopeGolfBanner from "./icon/header_dope_golf.webp";
import ROSS from "./icon/ross.webp";
import RYAN from "./icon/ryan.webp";
import MATT from "./icon/matt.webp";
import KEN from "./icon/ken.webp";
import MASK1 from "./icon/mask1.webp";
import MASK2 from "./icon/mask2.webp";
import MASK3 from "./icon/mask3.webp";
import TAB1 from "./icon/tab1.webp";
import TAB2 from "./icon/tab2.webp";
import ViewIcon from "./icon/view.png";
import HideIcon from "./icon/eye.png";

export {
  ViewIcon,
  HideIcon,
  MASK1,
  MASK2,
  MASK3,
  TAB1,
  TAB2,
  ROSS,
  RYAN,
  MATT,
  KEN,
  SearchIcon,
  WhiteLogo,
  PersonIcon,
  InstaIcon,
  Layer1Image,
  Layer2Image,
  Testimonal1,
  Testimonal2,
  Testimonal3,
  ContactImage,
  FlagImage,
  IntroImage,
  Golf1Image,
  Golf2Image,
  Golf3Image,
  Golf4Image,
  Golf5Image,
  Golf6Image,
  Golf7Image,
  Golf8Image,
  Partner1Image,
  Partner2Image,
  Partner3Image,
  Partner4Image,
  Partner5Image,
  Partner6Image,
  MenuIcon,
  CloseIcon,
  StripeIcon,
  SuccessIcon,
  DopeGolfBanner,
};
