import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import authStore from "../stores/auth";
import Loader from "../components/Loader";

const BecomeIntroducer = () => {
  const navigate = useNavigate();

  const { introducerRegisterApi, loading } = authStore((state) => ({
    introducerRegisterApi: state.introducerRegisterApi,
    loading: state.loading,
  }));

  const clickOnSubmit = (fields, resetForm) => {
    introducerRegisterApi(fields, navigate).then(
      (res) => res.status === 200 && resetForm()
    );
    return false;
  };

  return (
    <div>
      <main className="text-white md:pt-[60px] sm:pt-[40px]">
        <section className="h-[50vh] bg-no-repeat w-full bg-[url('../../assets/img/icon/intro.webp')]">
          <div className="container mx-auto px-4 h-full flex justify-center items-center">
            <h1 className="lg:text-6xl sm:text-4xl text-3xl">
              Become an Introducer
            </h1>
          </div>
        </section>
        <section>
          <div className="container mx-auto px-4">
            <div className="max-w-5xl mx-auto lg:text-xl lg:pb-16">
              <div className="py-28">
                <p>
                  Thank you for your interest in becoming a registered
                  Introducer for Foresight Sports Europe.
                </p>

                <p className="pt-9">
                  We value your support in growing the Foresight family, and
                  with each successful introduction that invests in one of our
                  products, we will reward you with either a cash payment or
                  Foresight credit you choose.
                </p>
              </div>
              <div>
                <h2 className="text-tgreen text-3xl">What you Need to do…</h2>
                <ol className="list-decimal py-7 font-normal text-lg px-8 text-gray-400">
                  <li className="py-3">
                    Please carefully complete all boxes below accurately and to
                    the best of your knowledge. We use this information to
                    review your application.
                  </li>
                  <li className="py-3">
                    Once you are happy everything is correct and there are no
                    typing mistakes, click “Submit Your Application for
                    Approval”
                  </li>
                  <li className="py-3">
                    A member of the Foresight Sports Europe team will review
                    your application request, usually within 24 hours.
                  </li>
                  <li className="py-3">
                    Once you are approved you will be sent, via email to the
                    address provided on this application, our Introducer
                    Agreement and associated Terms and Conditions.
                  </li>
                  <li className="py-3">
                    When you receive the email, simply follow the instructions.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="relative after:content-[''] after:w-full after:absolute after:top-0 after:left-0 after:bg-origin-border after:object-cover after:bg-center after:h-full bg-[url('../../assets/img/icon/contact.webp')] after:bg-cover after:z-[-1] py-20">
          <div className="container px-4 mx-auto">
            <div className="max-w-5xl mx-auto">
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  mobileNo: "",
                  address: "",
                  city: "",
                  state: "",
                  country: "",
                  zipcode: "",
                  latitude: 18.551,
                  longitude: 73.548,
                }}
                validationSchema={Yup.object().shape({
                  firstName: Yup.string().required("First name is required"),
                  lastName: Yup.string().required("Last name is required"),
                  email: Yup.string()
                    .email("Email is invalid")
                    .required("Email is required"),
                  mobileNo: Yup.string()
                    .required("Phone number is required")
                    .max(16, "Phone Number must be less than 16 characters"),
                  address: Yup.string().required("Address is required"),
                  city: Yup.string().required("City is required"),
                  state: Yup.string().required("State is required"),
                  country: Yup.string().required("Country is required"),
                  zipcode: Yup.string().required("Zipcode is required"),
                })}
                onSubmit={(fields, { resetForm }) => {
                  clickOnSubmit(fields, resetForm);
                }}
                render={({ errors, status, touched }) => (
                  <Form className="w-full h-full">
                    <h3 className="text-5xl">Introducer application form</h3>
                    <div className="my-10">
                      <h4 className="text-lg tracking-wide text-red-500 pb-4">
                        Basic Info
                      </h4>
                      <div className="lg:flex lg:flex-wrap">
                        <div className="lg:w-1/2 pr-3">
                          <label className="text-xs text-[#4F5052]">
                            First Name *
                          </label>
                          <Field
                            type="text"
                            name="firstName"
                            id="firstName"
                            className="block lg:w-full sm:w-full w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl"
                            placeholder="First Name"
                          />
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            className="text-red-500 text-xs"
                          />
                        </div>
                        <div className="lg:w-1/2">
                          <label className="text-xs text-[#4F5052] mt-6">
                            Last Name *
                          </label>
                          <Field
                            type="text"
                            name="lastName"
                            id="lastName"
                            className="block lg:w-full sm:w-full w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl"
                            placeholder="Last Name"
                          />
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            className="text-red-500 text-xs"
                          />
                        </div>
                      </div>
                      <label className="text-xs text-[#4F5052] mt-6">
                        Your email *
                      </label>
                      <Field
                        type="text"
                        name="email"
                        id="email"
                        className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl "
                        placeholder="Email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500 text-xs"
                      />
                      <label className="block text-xs text-[#4F5052] mt-6">
                        Phone Number*
                      </label>
                      {/* <select className="inline bg-transparent outline-none border-b border-b-[#4F5052] pb-3 text-[#4F5052] items-center ">
                        <option className="text-black">+ 91</option>
                        <option className="text-black">+ 49</option>
                        <option className="text-black">+ 39</option>
                        <option className="text-black">+ 90</option>
                        <option className="text-black">+ 81</option>
                        <option className="text-black">+ 98</option>
                      </select> */}
                      <Field
                        type="number"
                        name="mobileNo"
                        id="mobileNo"
                        className="w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl"
                        placeholder="000-000-0000"
                      />
                      <ErrorMessage
                        name="mobileNo"
                        component="div"
                        className="text-red-500 text-xs"
                      />
                    </div>

                    <div className="my-10">
                      <h4 className="text-lg tracking-wide text-red-500 pb-4">
                        Address Info
                      </h4>
                      <div className="lg:flex lg:flex-wrap">
                        <div className="lg:w-1/2 sm:w-full w-full pr-3">
                          <label className="text-xs text-[#4F5052] mt-6">
                            Address *
                          </label>
                          <Field
                            type="text"
                            name="address"
                            id="address"
                            className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl"
                            placeholder="First line address"
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="text-red-500 text-xs"
                          />
                          <label className="text-xs text-[#4F5052] mt-6">
                            Country*
                          </label>
                          <Field
                            type="text"
                            name="country"
                            id="country"
                            className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl"
                            placeholder="Country"
                          />
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="text-red-500 text-xs"
                          />
                          <label className="text-xs text-[#4F5052] mt-6">
                            State*
                          </label>
                          <Field
                            type="text"
                            name="state"
                            id="state"
                            className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl"
                            placeholder="State"
                          />
                          <ErrorMessage
                            name="state"
                            component="div"
                            className="text-red-500 text-xs"
                          />
                        </div>
                        <div className="lg:w-1/2 sm:w-full w-full">
                          <label className="text-xs text-[#4F5052] mt-6">
                            Town/City *
                          </label>
                          <Field
                            type="text"
                            name="city"
                            id="city"
                            className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl"
                            placeholder="Location"
                          />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="text-red-500 text-xs"
                          />
                          <label className="text-xs text-[#4F5052] mt-6">
                            Zipcode*
                          </label>
                          <Field
                            type="text"
                            name="zipcode"
                            id="zipcode"
                            className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 placeholder:text-[#4F5052] mb-1 placeholder:text-xl"
                            placeholder="Area Code"
                          />
                          <ErrorMessage
                            name="zipcode"
                            component="div"
                            className="text-red-500 text-xs"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <button
                        disabled={loading}
                        className={`${
                          !loading ? `bg-themecolor` : `bg-themecolor/40`
                        } flex px-4 py-2 rounded-lg font-light text-white text-sm mt-5`}
                      >
                        SUBMIT{loading && <Loader />}
                      </button>
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default BecomeIntroducer;
