import { toast } from "react-toastify";
import create from "zustand";
import { persist } from "zustand/middleware";
import { axiosApi } from "../helpers/axios";
import { ACCESS_TOKEN, firstCap } from "../utils/constants";

const authStore = create(
  persist(
    (set, get) => ({
      introducerRegisterData: {},
      loginData: {},
      updateUserData: {},
      createPaymentData: {},
      paymentStatusData: {},
      getAllPlanData: {},
      confirmPaymentData: {},
      cancelSubscriptionData: {},
      getUserData: {},
      createFeedbackData: {},
      getresetPWDData: {},
      loading: false,
      userCardLoading: false,
      error: null,

      introducerRegisterApi: async (payload, navigate) => {
        try {
          set({ loading: true, introducerRegisterData: {}, error: null });
          const response = await axiosApi.post(
            "/superadmin/introducer-register",
            payload
          );
          set({
            loading: false,
            introducerRegisterData: response.data,
            error: null,
          });
          toast.success(firstCap(response?.data?.msg));
          return response;
        } catch (error) {
          set({
            loading: false,
            introducerRegisterData: {},
            error: error.response.data.message,
          });
          toast.error(firstCap(error.response.data.message));
          return error.response.data.message;
        }
      },
      getUserDetailAPI: async (payload, navigate) => {
        try {
          set({ loading: true, getUserData: {}, error: null });
          const response = await axiosApi.get("auth/accessToken", null);
          set({
            loading: false,
            getUserData: response.data,
            error: null,
          });
          return response;
        } catch (error) {
          set({
            loading: false,
            getUserData: {},
            error: error.response.data.message,
          });
          // toast.error(firstCap(error.response.data.message));
          return error.response.data.message;
        }
      },
      resetPWDApi: async (payload, navigate) => {
        try {
          set({ loading: false, getresetPWDData: {}, error: null });
          const response = await axiosApi.post(
            "/SuperAdmin/reset-password",
            payload
          );
          set({
            loading: false,
            getresetPWDData: response.data,
            error: null,
          });
          toast.success(firstCap(response?.data?.msg));
          return response;
        } catch (error) {
          set({
            loading: false,
            getresetPWDData: {},
            error: error.response.data.message,
          });
          // toast.error(firstCap(error.response.data.message));
          return error.response.data.message;
        }
      },
      loginApi: async (payload, navigate) => {
        try {
          set({ loading: true, loginData: {}, error: null });
          const response = await axiosApi.post("/superadmin/login", payload);
          set({ loading: false, loginData: response.data, error: null });

          localStorage.setItem(
            ACCESS_TOKEN,
            response?.data?.tokens?.access?.token
          );
          axiosApi.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response?.data?.tokens?.access?.token}`;
          localStorage.setItem("userId", response?.data?.user?.id);
          localStorage.setItem("user", JSON.stringify(response?.data?.user));
          if (response?.data?.user?.isPayment === false) {
            navigate(`/register/s1xuuy5ci7ty8iw9`);
          } else {
            navigate("/");
          }

          toast.success(firstCap(response?.data?.msg));

          return response;
        } catch (error) {
          set({
            loading: false,
            loginData: {},
            error: error.response.data.message,
          });
          toast.error(firstCap(error.response.data.message));
          return error.response.data.message;
        }
      },
      updateUserApi: async (payload, navigate) => {
        try {
          set({ loading: true, updateUserData: {}, error: null });
          const response = await axiosApi.put(
            `/auth/user-update-profile/${payload?.email}`,
            payload?.data
          );
          set({ loading: false, updateUserData: response.data, error: null });
          return response;
        } catch (error) {
          set({
            loading: false,
            updateUserData: {},
            error: error.response.data.message,
          });
          toast.error(firstCap(error.response.data.message));
          return error.response.data.message;
        }
      },
      createPaymentApi: async (payload) => {
        try {
          set({ loading: true, createPaymentData: {}, error: null });
          const response = await axiosApi.post(
            `/payment/create-paymentintents`,
            payload
          );
          set({
            loading: false,
            createPaymentData: response.data,
            error: null,
          });
          return response;
        } catch (error) {
          set({
            loading: false,
            createPaymentData: {},
            error: error.response.data.message,
          });
          toast.error(firstCap(error.response.data.message));
          return error.response.data.message;
        }
      },
      paymentStatusApi: async (payload) => {
        try {
          set({ loading: true, paymentStatusData: {}, error: null });
          let url = "";
          // if (payload?.coupon) {
          //   url = `/payment/payment-status?priceId=${payload?.priceId}&userId=${payload?.userId}&coupon=${payload?.coupon}`;
          // } else {
          url = `/payment/payment-status?priceId=${payload?.priceId}&userId=${payload?.userId}`;
          // }
          const response = await axiosApi.get(url, null);
          set({
            loading: false,
            paymentStatusData: response.data,
            error: null,
          });
          localStorage.setItem(
            ACCESS_TOKEN,
            response?.data?.tokens?.access?.token
          );
          axiosApi.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response?.data?.tokens?.access?.token}`;
          return response;
        } catch (error) {
          set({
            loading: false,
            paymentStatusData: {},
            error: error.response.data.message,
          });
          toast.error(firstCap(error.response.data.message));
          return error?.response?.data?.code;
        }
      },
      userCardListApi: async (payload) => {
        try {
          set({ userCardLoading: true, userCardData: {}, error: null });
          const response = await axiosApi.get(
            `/payment/user-card-list?userId=${payload?.userId}`,
            null
          );
          set({
            userCardLoading: false,
            userCardData: response.data,
            error: null,
          });
          return response;
        } catch (error) {
          set({
            userCardLoading: false,
            userCardData: {},
            error: error.response.data.message,
          });
          toast.error(firstCap(error.response.data.message));
          return error.response.data.message;
        }
      },
      getAllPlanApi: async (payload) => {
        try {
          set({ loading: true, getAllPlanData: {}, error: null });
          const response = await axiosApi.get(`/price/get-all-plans`, null);
          set({
            loading: false,
            getAllPlanData: response?.data?.data?.results,
            error: null,
          });
          return response;
        } catch (error) {
          set({
            loading: false,
            getAllPlanData: {},
            error: error.response.data.message,
          });
          toast.error(firstCap(error.response.data.message));
          return error.response.data.message;
        }
      },
      addCardApi: async (payload) => {
        try {
          set({ loading: true, addCardData: {}, error: null });
          const response = await axiosApi.post(`/payment/add-card`, payload);
          set({
            loading: false,
            addCardData: response?.data,
            error: null,
          });
          return response;
        } catch (error) {
          set({
            loading: false,
            addCardData: {},
            error: error.response.data.message,
          });
          toast.error(firstCap(error.response.data.message));
          return error.response.data.message;
        }
      },
      createFeedbackApi: async (payload) => {
        try {
          set({ loading: true, createFeedbackData: {}, error: null });
          const response = await axiosApi.post(
            `/feedback/create-feedback`,
            payload
          );
          set({
            loading: false,
            createFeedbackData: response?.data,
            error: null,
          });
          toast.success(firstCap(response?.data?.msg));
          return response;
        } catch (error) {
          set({
            loading: false,
            createFeedbackData: {},
            error: error.response.data.message,
          });
          toast.error(firstCap(error.response.data.message));
          return error.response.data.message;
        }
      },
      applyCouponCodeApi: async ({ coupon }) => {
        try {
          set({ loading: true, applyCouponCodeData: {}, error: null });
          const response = await axiosApi.post(
            `/code/verify-coupon?coupon=${coupon}`,
            null
          );
          set({
            loading: false,
            applyCouponCodeData: response?.data,
            error: null,
          });
          toast.success(firstCap(response?.data?.msg));
          return response;
        } catch (error) {
          set({
            loading: false,
            applyCouponCodeData: {},
            error: error.response.data.message,
          });
          toast.error(firstCap(error.response.data.message));
          return error.response.data.message;
        }
      },
      cancelSubscriptionApi: async ({ data, setDCancelModal }) => {
        try {
          set({ loading: true, cancelSubscriptionData: {}, error: null });
          const response = await axiosApi.delete(
            `/payment/delete-subscriptions?subscriptionId=${data}`,
            null
          );
          set({
            loading: false,
            cancelSubscriptionData: response?.data,
            error: null,
          });
          toast.success(firstCap(response?.data?.msg));
          setDCancelModal(false);
          return response;
        } catch (error) {
          set({
            loading: false,
            cancelSubscriptionData: {},
            error: error.response.data.message,
          });
          toast.error(firstCap(error.response.data.message));
          return error.response.data.message;
        }
      },
      setConfirmPaymentDataApi: async (payload) => {
        set({ loading: false, confirmPaymentData: payload, error: null });
      },
      setPaymentDataApi: async () => {
        set({ loading: false, paymentStatusData: {}, error: null });
        set({ loading: false, addCardData: {}, error: null });
        set({ loading: false, confirmPaymentData: {}, error: null });
      },
    }),
    { name: "auth-storage", getStorage: () => sessionStorage }
  )
);

export default authStore;
