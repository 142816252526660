import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Error = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen p-2 p-sm-3">
      <div className="w-full h-full flex flex-col justify-center text-center my-auto text-white">
        <h2 className="mb-1">Page Not Found 🕵🏻‍♀️</h2>
        <p className="mb-2">
          Oops! 😖 The requested URL was not found on this server.
        </p>
        <div className="w-full flex justify-center">
          <button
            onClick={() => navigate("/")}
            className={`bg-themecolor flex justify-between px-4 py-2 rounded-lg font-light text-white text-sm mt-5`}
          >
            Back to home
          </button>
        </div>

        {/* <img className='img-fluid' src={source} alt='Not authorized page' /> */}
      </div>
    </div>
  );
};

export default Error;
