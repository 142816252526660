import React from "react";
import { Route, Routes } from "react-router-dom";
import OverView from "./OverView";
import Team from "./Team";

const AboutRoute = () => {
  return (
    <Routes>
      <Route exact index element={<OverView />} />
      <Route exact path="/team" element={<Team />} />
    </Routes>
  );
};

export default AboutRoute;
