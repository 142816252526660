import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { memo, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import authStore from "../stores/auth";

const AddCardForm = ({ setAddCardModal, userId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [addCard, setAddCard] = useState(false);

  const { addCardApi } = authStore((state) => ({
    addCardApi: state.addCardApi,
  }));

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setloading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    stripe
      .createToken(elements.getElement(CardElement))
      .then(function (result) {
        if (result?.token) {
          addCardApi({
            token: result?.token?.id,
            userId: userId,
          }).then(function (res) {
            if (res.status === 200) {
              toast.success(res?.data?.msg);
            }
            setloading(false);
          });
        }
        setErrorMessage(result?.error || "");
        // Handle result.error or result.token
      });
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <div className="bg-slate-300 p-2 rounded-lg">
        <CardElement
          options={CARD_ELEMENT_OPTIONS}
          onChange={(e) => setAddCard(e?.complete)}
        />
      </div>
      <p className="text-red-500 mt-2">{errorMessage}</p>
      <div className="flex justify-end mt-2">
        <button
          type="submit"
          disabled={!stripe || !elements}
          className="btn !rounded my-3"
        >
          <div className="flex justify-end">
            <button
              disabled={loading}
              type="submit"
              className={`bg-transparent border border-1 border-slate-500 flex px-4 py-2 rounded-lg font-light text-slate-500 text-sm my-3`}
              onClick={() => setAddCardModal(false)}
            >
              CANCEL
            </button>
            <button
              type="submit"
              disabled={!addCard || loading}
              className={`${
                addCard || loading ? `bg-themecolor` : `bg-themecolor/40`
              } ml-3 flex px-4 py-2 rounded-lg font-light text-white text-sm my-3`}
              onClick={() => setloading(true)}
            >
              ADD CARD{loading && <Loader />}
            </button>
          </div>
        </button>
      </div>
    </form>
  );
};

export default memo(AddCardForm);
